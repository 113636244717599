import ErrorIcon from '@mui/icons-material/Error';
import FilterListIcon from '@mui/icons-material/FilterList';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Button,
  Container,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  OutlinedInput,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import dayjs from 'dayjs';
import { FirestoreError, orderBy, query, where } from 'firebase/firestore';
import { getCompaniesCol, getOurLicensesCol } from 'flyid-core/dist/Util/database';
import MultiSelectButton from 'flyid-ui-components/dist/utils/MultiSelectButton';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import {
  getAndAccumulateQueries,
  getSlicedWhereArrayOperandQuery
} from 'src/util/helpers/database';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(70),
    maxWidth: spacing(70)
  },
  titleContainer: {
    maxWidth: spacing(75)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  },
  filter: {
    alignContent: 'center',
    marginLeft: spacing(1.5),
    height: spacing(7)
  },
  itemIcon: {
    marginLeft: spacing(5)
  },
  itemText: {
    margin: spacing(1, 1),
    paddingRight: spacing(5)
  }
}));

type LicenseData = {
  id: string;
  company: string;
  description: string;
  expDate: Date;
};

const CriticalLicenses: React.FC = () => {
  const classes = useStyles();
  const { spacing, text } = useAppTheme();
  const { $t } = useIntl();

  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [licenseDataList, setLicenseDataList] = useState<LicenseData[]>([]);
  const [loadingLicenses, setLoadingLicenses] = useState(true);
  const [errorLicenses, setErrorLicenses] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterOption, setFilterOption] = useState<string>('All');

  const [companiesQS, loadingCompanies, errorCompanies] = useCollection(
    buildCollectionRef(getCompaniesCol())
  );

  const companiesMap = querySnapToMap(companiesQS) ?? {};
  const companies = Object.keys(companiesMap);

  const isLicenseLoaded = !loadingLicenses && !!licenseDataList;
  const isCompanyLoaded = !loadingCompanies && !!companies;
  const isLoaded = isCompanyLoaded && isLicenseLoaded;
  const isError = errorCompanies || errorLicenses;

  const currentDate = dayjs().toDate();
  const oneMonthFromNow = dayjs().add(1, 'months').toDate();
  const threeMonthsFromNow = dayjs().add(3, 'months').toDate();

  useEffect(() => {
    setLoadingLicenses(true);

    const queries = getSlicedWhereArrayOperandQuery(
      query(
        buildCollectionRef(getOurLicensesCol()),
        where('expDate', '<=', threeMonthsFromNow),
        orderBy('expDate', 'asc')
      ),
      'company',
      'in',
      selectedCompanies
    );

    getAndAccumulateQueries(queries)
      .then((docSnaps) => {
        const licenses = docSnaps.map((snap) => {
          const licenseData = snap.data();
          const data = {
            id: snap.id,
            company: licenseData.company,
            description: licenseData.description,
            expDate: licenseData.expDate
          } as LicenseData;

          return data;
        });
        setLicenseDataList(licenses);
        setLoadingLicenses(false);
      })
      .catch((err) => {
        if (err instanceof Error) {
          setErrorLicenses(err.message);
        } else {
          setErrorLicenses('An unknown error occurred');
        }
        setLoadingLicenses(false);
      });
  }, [selectedCompanies]);

  const filterLicenses = (licenseData: LicenseData[]) => {
    switch (filterOption) {
      case 'Expired':
        return licenseData.filter((license) => license.expDate < currentDate);
      case 'About to expire':
        return licenseData.filter(
          (license) => license.expDate > currentDate && license.expDate <= oneMonthFromNow
        );
      case 'Expiration is close':
        return licenseData.filter(
          (license) => license.expDate > oneMonthFromNow && license.expDate <= threeMonthsFromNow
        );
      default:
        return licenseData;
    }
  };

  const handleMultipleSelectChange = (company: string[]) => {
    setSelectedCompanies(company);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFilterOptionSelect = (option: string) => {
    setFilterOption(option);
    handleMenuClose();
  };

  return (
    <>
      {!isError ? (
        <Container className={classes.container}>
          {isCompanyLoaded ? (
            <>
              <Grid container className={classes.titleContainer}>
                <Typography variant="h4" sx={text.title}>
                  {$t({ id: 'admin.criticalLicenses' })}
                </Typography>
                <Typography variant="subtitle1" sx={text.subtitle}>
                  {$t({ id: 'admin.criticalLicensesSubtitle' })}
                </Typography>
              </Grid>

              <Grid container className={classes.mainGrid}>
                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel id="companies-select-label">{$t({ id: 'companies' })}</InputLabel>
                    <MultiSelectButton
                      options={companies}
                      onChange={handleMultipleSelectChange}
                      selectProps={{
                        labelId: 'companies-select-label',
                        input: <OutlinedInput label={$t({ id: 'companies' })} />
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <Button
                    aria-controls="filter-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    variant="outlined"
                    size="small"
                    className={classes.filter}
                    startIcon={<FilterListIcon />}
                  >
                    {filterOption === 'All' ? (
                      <Typography variant="body2">{$t({ id: 'admin.filterByAll' })}</Typography>
                    ) : filterOption === 'Expired' ? (
                      <Typography variant="body2">{$t({ id: 'expired' })}</Typography>
                    ) : filterOption === 'About to expire' ? (
                      <Typography variant="body2">{$t({ id: 'aboutToExpire' })}</Typography>
                    ) : filterOption === 'Expiration is close' ? (
                      <Typography variant="body2">{$t({ id: 'expirationIsClose' })}</Typography>
                    ) : null}
                  </Button>

                  <Menu
                    id="filter-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleFilterOptionSelect('All')}>
                      {$t({ id: 'all' })}
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterOptionSelect('Expired')}>
                      {$t({ id: 'expired' })}
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterOptionSelect('About to expire')}>
                      {$t({ id: 'aboutToExpire' })}
                    </MenuItem>
                    <MenuItem onClick={() => handleFilterOptionSelect('Expiration is close')}>
                      {$t({ id: 'expirationIsClose' })}
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container spacing={spacing(1)} className={classes.titleContainer}>
                <Grid item xs={12}>
                  <Skeleton variant="text" height={spacing(10)} animation="wave" />
                  <Skeleton variant="text" height={spacing(5)} animation="wave" />
                </Grid>
                <Grid item xs={9}>
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                </Grid>
                <Grid item xs={3}>
                  <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                </Grid>
              </Grid>
            </>
          )}

          {isLoaded ? (
            <Grid container spacing={2} className={classes.mainGrid}>
              <Grid item xs={12}>
                <List
                  subheader={
                    <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                      {!isEmpty(selectedCompanies) ? $t({ id: 'admin.criticalLicenses' }) : null}
                    </ListSubheader>
                  }
                >
                  {!isEmpty(licenseDataList) ? (
                    filterLicenses(licenseDataList).length > 0 ? (
                      filterLicenses(licenseDataList).map((license) => {
                        const parseDate = dayjs(license.expDate).format('DD/MM/YYYY');

                        return (
                          <ListItemButton
                            key={license.id}
                            alignItems="flex-start"
                            className={classes.listItem}
                          >
                            <ListItemText
                              id={license.id}
                              className={classes.itemText}
                              primary={$t(
                                { id: 'admin.criticalLicensesListItemText1' },
                                {
                                  company: license.company,
                                  licenseId: license.id
                                }
                              )}
                              secondary={$t(
                                { id: 'admin.criticalLicensesListItemText2' },
                                {
                                  description: license.description,
                                  nl: <br key={`${license.id}nl`} />,
                                  expDate: parseDate
                                }
                              )}
                            />
                            <ListItemIcon>
                              {license.expDate < currentDate ? (
                                <Tooltip title={$t({ id: 'expired' })}>
                                  <ErrorIcon
                                    className={classes.itemIcon}
                                    fontSize="large"
                                    color="error"
                                  />
                                </Tooltip>
                              ) : license.expDate <= oneMonthFromNow ? (
                                <Tooltip title={$t({ id: 'aboutToExpire' })}>
                                  <WarningIcon
                                    className={classes.itemIcon}
                                    fontSize="large"
                                    color="warning"
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title={$t({ id: 'expirationIsClose' })}>
                                  <NotificationImportantIcon
                                    className={classes.itemIcon}
                                    fontSize="large"
                                    color="warning"
                                  />
                                </Tooltip>
                              )}
                            </ListItemIcon>
                          </ListItemButton>
                        );
                      })
                    ) : selectedCompanies.length > 0 ? (
                      <ListItem
                        key="emptyLicenses"
                        alignItems="flex-start"
                        className={classes.listItem}
                      >
                        <ListItemText
                          id="emptyLicenses"
                          className={classes.itemText}
                          primary={$t({ id: 'admin.manLicNoLicenses' })}
                        />
                      </ListItem>
                    ) : null
                  ) : selectedCompanies.length > 0 ? (
                    <ListItem
                      key="emptyLicenses"
                      alignItems="flex-start"
                      className={classes.listItem}
                    >
                      <ListItemText
                        id="emptyLicenses"
                        className={classes.itemText}
                        primary={$t({ id: 'admin.manLicNoLicenses' })}
                      />
                    </ListItem>
                  ) : null}
                </List>
              </Grid>
            </Grid>
          ) : (
            <Grid container className={classes.mainGrid}>
              <Grid item xs={12} sx={{ mt: spacing(2) }}>
                {Array.from({ length: 6 }).map((_, index) => (
                  <Skeleton
                    key={`skeleton-${index}`}
                    variant="rounded"
                    height={spacing(10)}
                    animation="wave"
                    className={classes.margin}
                  />
                ))}
              </Grid>
            </Grid>
          )}
        </Container>
      ) : (
        <Typography variant="body1">
          An error occurred: {(isError as FirestoreError).message}
        </Typography>
      )}
    </>
  );
};

export default CriticalLicenses;
