import React from 'react';
import ReactDom from 'react-dom';
// eslint-disable-next-line import/no-unassigned-import
import './App.css';
// Redux
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import Routes from './routes';
//Internationalization
import axios from 'axios';
import { RouterProvider } from 'react-router-dom';
import environment from 'src/environment';
import GlobalFeedback from './components/layout/GlobalFeedback';
import LandingLoading from './components/layout/LandingLoading';
import NavBar from './components/layout/NavBar';
import IntlAndTheme from './components/utils/IntlAndTheme';
import { initFirebase } from './firebase/firebase';
import { setupFirebaseListeners } from './firebase/listeners';
import { createRouter } from './router';
import initStore from './redux/store';

// Init axios base url with server url
axios.defaults.baseURL = `${environment.serverApi}`;

// Make sure firebase is properly initialized
const firebaseData = initFirebase();

export const store = initStore(firebaseData);

setupFirebaseListeners({ store, ...firebaseData });

ReactDom.render(
  <React.StrictMode>
    {/* Redux provider */}
    {/* @ts-expect-error -> This is not a real problem. */}
    <Provider store={store}>
      {/* Translation and theme */}
      <IntlAndTheme>
        {/* First load should land into this page */}
        <LandingLoading>
          {/* App wrapped by a router provider fed with a singleton router */}
          <RouterProvider
            router={createRouter(
              <div className="App">
                {/* Navbar with top toolbar and sidebar */}
                <NavBar>
                  {/* Actual content in routes switch */}
                  <Routes />
                </NavBar>
                {/* Handle various UI feedback mechanism */}
                <GlobalFeedback />
              </div>
            )}
            future={{ v7_startTransition: true }}
          />
        </LandingLoading>
      </IntlAndTheme>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
