import {
  Box,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import { query, where } from 'firebase/firestore';
import { getCompaniesCol, getUsersCol } from 'flyid-core/dist/Util/database';
import { getFirst } from 'flyid-core/dist/Util/helpers';
import MultiSelectButton from 'flyid-ui-components/dist/utils/MultiSelectButton';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { AddUserParams } from 'src/redux/actions/userActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import LoadingButton from '../widgets/LoadingButton';
import { AuthenticationProvider } from 'flyid-core/dist/Database/Models/AuthProvider';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(50),
    maxWidth: spacing(65)
  },
  titleContainer: {
    maxWidth: spacing(80)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(0.5)
  }
}));

const initialUserData = {
  company: '',
  email: '',
  firstName: '',
  lastName: '',
  employeeId: '',
  sendEmail: false,
  password: '',
  permissions: [] as string[],
  authDomains: [] as string[],
  parent: '',
  companies: [] as string[]
};

const initialPermissions = {
  missingPermissions: false,
  missingDomains: false
};

const pilot = 'pilot';
const assistant = 'assistant';
const moderator = 'moderator';
const permissions = [pilot, assistant, moderator];

type UserFormData = typeof initialUserData;
type UserPermissionsData = typeof initialPermissions;
type State = UserFormData & UserPermissionsData;

const getAuthProviders = (
  _authProviders: { [company: string]: AuthenticationProvider } | undefined,
  _selectedCompanies: string[]
) => [
  ...new Set(
    _selectedCompanies.map((c) => _authProviders?.[c]?.provider).filter((v): v is string => !!v)
  )
];

const AddUser: React.FC = () => {
  const classes = useStyles();
  const { text, spacing, select } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { ui, areAuthProvidersLoaded, authProviders } = useAppSelector((s) => {
    const authProvs = s.firestore.authProviders;
    return {
      ui: s.ui,
      areAuthProvidersLoaded: authProvs.areAuthProvidersLoaded,
      authProviders: authProvs.providers
    };
  });

  const [state, setState] = useStateReducer<State>(
    Object.assign({}, initialUserData, initialPermissions)
  );
  const [isKeyUser, setIsKeyUser] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);

  const [companiesQS, loadingCompanies, errorCompanies] = useCollectionOnce(
    buildCollectionRef(getCompaniesCol())
  );
  const companiesMap = querySnapToMap(companiesQS) ?? {};
  const companies = Object.keys(companiesMap);

  const [parentQS, loadingParent, errorParent] = useCollectionOnce(
    state.company
      ? query(
          buildCollectionRef(getUsersCol()),
          where('company', '==', state.company),
          where('moderator', '==', true)
        )
      : undefined
  );
  const parents = querySnapToMap(parentQS) ?? {};
  const parent = getFirst(parents) ?? undefined;

  const isError = errorCompanies || errorParent;
  const isCompanyLoaded = !loadingCompanies && !!companies && areAuthProvidersLoaded;
  const isParentLoaded = !loadingParent && !!parent;

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ [e.target.name]: e.target.checked });
  };

  const handleMultipleCompanySelectChange = (_companies: string[]) => {
    // When adding a new company, if the company has providers, make sure providers are properly set
    // and guarantee key users have the same providers for all companies.
    if (_companies.length > selectedCompanies.length) {
      // If "select all" and at least one provider exists, there should be error, since most
      // companies do not have providers.
      const selctedAll = companies.length === _companies.length;
      const showError = selctedAll && !!getAuthProviders(authProviders, companies).length;
      const selectedProviders = getAuthProviders(authProviders, selectedCompanies);
      for (const newlySelected of _companies.filter((v) => !selectedCompanies.includes(v))) {
        const newlySelectedProvider = authProviders?.[newlySelected]?.provider;
        if (
          showError ||
          // New selection has provider and
          (newlySelectedProvider &&
            // Is different than selected ones
            ((selectedProviders.length && !selectedProviders.includes(newlySelectedProvider)) ||
              // There are companies selected that have no providers
              selectedCompanies.length)) ||
          // New selection has no provider but there are selected providers already
          (!newlySelectedProvider && selectedProviders.length)
        ) {
          dispatch(
            updateUi({
              snackbar: {
                message: 'Cannot select companies with multiple providers for the same key user!',
                severity: 'error',
                show: true
              }
            })
          );
          // Avoid change but update state in order to update selection
          setSelectedCompanies(selectedCompanies);
          return selectedCompanies;
        }
      }
    }
    setSelectedCompanies(_companies);
    return _companies;
  };

  const handleMultipleSelectChange = (e: SelectChangeEvent<string[]>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const selectedAuthProviders = getAuthProviders(
      authProviders,
      isKeyUser ? selectedCompanies : [state.company]
    );
    if (selectedAuthProviders.length > 1) {
      dispatch(
        updateUi({
          snackbar: {
            message: 'Must be a single auth provider!',
            severity: 'error',
            show: true
          }
        })
      );
    }

    const userData: AddUserParams = {
      company: state.company,
      firstName: state.firstName,
      lastName: state.lastName,
      employeeId: state.employeeId,
      email: state.email,
      password: state.password,
      authDomains: state.authDomains,
      assistant: !!state.permissions.includes(assistant),
      pilot: !!state.permissions.includes(pilot),
      moderator: !!state.permissions.includes(moderator),
      keyUser: isKeyUser,
      parent: state.parent,
      usesAuthProvider: selectedAuthProviders.length === 1
    };
    if (isKeyUser) {
      userData.company = selectedCompanies[0];
      userData.companies = selectedCompanies;
    }

    if (!isKeyUser) {
      const isPilot = !!state.permissions.includes(pilot);
      if (state.password) {
        if (isPilot) {
          if (!/^\d{6}$/.test(state.password)) {
            dispatch(
              updateUi({
                snackbar: {
                  message: $t({ id: 'err.pilotPassword' }),
                  severity: 'error',
                  show: true
                }
              })
            );
            return;
          }
        }
      }

      const missingPermissions = !state.permissions.length;
      if (missingPermissions) {
        setState({
          missingPermissions
        });
        return;
      }
    }

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'manUsr.addUsrConfTitle' }),
          message: $t(
            { id: 'manUsr.addUsrConfMsg' },
            {
              firstName: <b key={`mdb0${userData.firstName}`}>{userData.firstName}</b>,
              lastName: <b key={`mdb1${userData.lastName}`}>{userData.lastName}</b>
            }
          ),
          show: true
        }).setConfirmAction(Actions.ADD_USER, userData)
      })
    );
  };

  const renderCommonsFields = () => {
    return (
      <>
        <Grid container item spacing={2} xs={12} alignContent="flex-start">
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              id="firstName"
              name="firstName"
              type="text"
              label={$t({ id: 'firstName' })}
              value={state.firstName}
              onChange={handleTextChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              id="lastName"
              name="lastName"
              type="text"
              label={$t({ id: 'lastName' })}
              value={state.lastName}
              onChange={handleTextChange}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="employeeId"
            name="employeeId"
            type="text"
            label={$t({ id: 'employeeId' })}
            value={state.employeeId}
            onChange={handleTextChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            required
            id="email"
            name="email"
            type="email"
            label={$t({ id: 'addUsr.email' })}
            value={state.email}
            onChange={handleTextChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="password"
            name="password"
            type="password"
            label={$t({ id: 'addUsr.pw' })}
            value={state.password}
            onChange={handleTextChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.sendEmail}
                name="sendEmail"
                onChange={handleCheckboxChange}
              />
            }
            label={
              <Typography variant="body2">
                {$t({ id: 'admin.sendEmailForConfirmation' })}
              </Typography>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            content={$t({ id: 'submit' })}
            type="submit"
            className={classes.button}
            isLoading={ui.loadingButton.isUserActionLoading}
          />
        </Grid>
      </>
    );
  };

  return (
    <>
      {!isError ? (
        <form onSubmit={handleSubmit}>
          <Container className={classes.container}>
            {isCompanyLoaded ? (
              <>
                <Grid container className={classes.titleContainer}>
                  <Typography variant="h4" sx={text.title}>
                    {$t({ id: 'manUsr.addUsr' })}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: spacing(2) }}>
                    {$t({ id: 'admin.addUserSubtitle' })}
                  </Typography>
                </Grid>

                <Grid container spacing={2} className={classes.mainGrid}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isKeyUser}
                          onChange={(e) => setIsKeyUser(e.target.checked)}
                        />
                      }
                      label={<Typography variant="body2">{$t({ id: 'isKeyUser' })}</Typography>}
                    />
                  </Grid>

                  {isKeyUser ? (
                    <>
                      <Grid item xs={12}>
                        <FormControl fullWidth required error={isEmpty(companies)}>
                          <InputLabel id="companies-select-label">
                            {$t({ id: 'companies' })}
                          </InputLabel>
                          <MultiSelectButton
                            options={companies}
                            onChange={handleMultipleCompanySelectChange}
                            selectProps={{
                              labelId: 'companies-select-label',
                              input: <OutlinedInput label={$t({ id: 'companies' })} />
                            }}
                            itemRender={(option) => {
                              const provider = authProviders?.[option]?.provider;
                              return (
                                <ListItemText
                                  primary={`${option}${provider ? ` (SSO: ${provider})` : ''}`}
                                />
                              );
                            }}
                          />
                        </FormControl>
                      </Grid>

                      {isCompanyLoaded ? renderCommonsFields() : null}
                    </>
                  ) : null}

                  {!isKeyUser ? (
                    <>
                      <Grid item xs={12}>
                        {isCompanyLoaded ? (
                          <FormControl fullWidth error={isEmpty(companies)}>
                            <InputLabel id="company-select-label">
                              {$t({ id: 'admin.company' })}
                            </InputLabel>
                            <Select
                              required
                              labelId="company-select-label"
                              id="company-select"
                              name="company"
                              onChange={handleSelectChange}
                              value={state.company}
                              input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                            >
                              {Object.keys(companiesMap).map((value, index) => (
                                <MenuItem key={index} value={value}>
                                  {value}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        {isCompanyLoaded ? (
                          <FormControl required fullWidth error={state.missingPermissions}>
                            <InputLabel id="permissions-label">
                              {$t({ id: 'permissions' })}
                            </InputLabel>
                            <Select
                              labelId="permissions-label"
                              id="permissions"
                              name="permissions"
                              multiple
                              value={state.permissions}
                              onChange={handleMultipleSelectChange}
                              onClose={() => {
                                if (state.missingPermissions) {
                                  setState({
                                    missingPermissions: !!state.permissions.length
                                  });
                                }
                              }}
                              input={<OutlinedInput label={$t({ id: 'permissions' })} />}
                              renderValue={(selected) => (
                                <div className={classes.chips}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={$t({ id: value })} />
                                  ))}
                                </div>
                              )}
                              MenuProps={select.getMenuProps()}
                              disabled={!state.company}
                            >
                              {permissions.map((perm) => (
                                <MenuItem key={perm} value={perm}>
                                  <Checkbox checked={state.permissions.indexOf(perm) > -1} />
                                  <ListItemText primary={$t({ id: perm })} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        ) : (
                          <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                        )}
                      </Grid>

                      {!state.permissions.includes(moderator) && !!state.permissions.length && (
                        <>
                          {isParentLoaded ? (
                            <Grid item xs={12}>
                              <FormControl required fullWidth>
                                <InputLabel id="parent-select-label">
                                  {$t({ id: 'parent' })}
                                </InputLabel>
                                <Select
                                  labelId="parent-select-label"
                                  id="parent"
                                  name="parent"
                                  onChange={handleSelectChange}
                                  value={state.parent ?? ''}
                                  input={<OutlinedInput label={$t({ id: 'parent' })} />}
                                >
                                  {Object.entries(parents).map(([parentId, parentData]) => (
                                    <MenuItem key={parentId} value={parentId}>
                                      {`${parentData.firstName} ${parentData.lastName} (${parentId})`}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                          ) : (
                            <Skeleton variant="rounded" height={spacing(7)} animation="wave" />
                          )}

                          {state.parent ? (
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <InputLabel id="authdomains-label">
                                  {$t({ id: 'authDomains' })}
                                </InputLabel>
                                <Select
                                  labelId="authdomains-label"
                                  id="authdomains"
                                  name="authDomains"
                                  multiple
                                  value={state.authDomains}
                                  onChange={handleMultipleSelectChange}
                                  onClose={() => {
                                    if (state.missingDomains) {
                                      setState({
                                        missingDomains: !!state.authDomains.length
                                      });
                                    }
                                  }}
                                  input={<OutlinedInput label={$t({ id: 'authDomains' })} />}
                                  renderValue={(selected) => (
                                    <Box
                                      sx={{ display: 'flex', flexWrap: 'wrap', gap: spacing(0.5) }}
                                    >
                                      {selected.map((value) => (
                                        <Chip key={value} label={$t({ id: value })} />
                                      ))}
                                    </Box>
                                  )}
                                  MenuProps={select.getMenuProps()}
                                >
                                  {isParentLoaded ? (
                                    parent.authDomains.map((domain: string) => {
                                      return (
                                        <MenuItem key={domain} value={domain}>
                                          <Checkbox
                                            checked={state.authDomains.indexOf(domain) > -1}
                                          />
                                          <ListItemText primary={domain} />
                                        </MenuItem>
                                      );
                                    })
                                  ) : (
                                    <MenuItem value={''} disabled>
                                      <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                                    </MenuItem>
                                  )}
                                </Select>
                              </FormControl>
                            </Grid>
                          ) : null}
                        </>
                      )}

                      {isCompanyLoaded ? renderCommonsFields() : null}
                    </>
                  ) : null}
                </Grid>
              </>
            ) : (
              <>
                <Grid container className={classes.titleContainer}>
                  <Grid item xs={12}>
                    <Skeleton
                      variant="text"
                      height={spacing(10)}
                      width={spacing(70)}
                      animation="wave"
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Skeleton variant="text" height={spacing(4)} animation="wave" />
                  </Grid>
                </Grid>

                <Grid container className={classes.mainGrid}>
                  <Grid item xs={12}>
                    <Skeleton variant="text" width={spacing(20)} height={spacing(5)} />
                    {Array.from({ length: 6 }).map((_, index) => (
                      <Box key={`skeleton-${index}`}>
                        <Skeleton
                          variant="rounded"
                          height={spacing(7)}
                          animation="wave"
                          className={classes.margin}
                        />
                      </Box>
                    ))}
                  </Grid>

                  <Grid item xs={12}>
                    <Skeleton
                      variant="text"
                      height={spacing(5)}
                      width={spacing(30)}
                      animation="wave"
                      className={classes.margin}
                    />
                  </Grid>

                  <Grid item xs={7}>
                    <Skeleton
                      variant="rounded"
                      height={spacing(5)}
                      width={spacing(20)}
                      animation="wave"
                      className={classes.margin}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        </form>
      ) : (
        <Typography variant="body1">An error occurred: {isError.message}</Typography>
      )}
    </>
  );
};

export default AddUser;
