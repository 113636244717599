import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { Firestore, connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { FirebaseStorage, getStorage } from 'firebase/storage';
import environment, { Environment, getDevConfig } from 'src/environment';
import {
  FirebaseConfig,
  productionConfig,
  productionV1Config,
  stagingConfig,
  stagingV1Config
} from 'src/util/config';

function getFirebaseConfig(env: Environment) {
  let config: FirebaseConfig = productionConfig;
  if (!env.isProduction) {
    if (!env.isDevelopment) {
      // Using staging project
      config = env.isV1 ? stagingV1Config : stagingConfig;
    } else {
      // Using development project, force synchronous module import
      config = getDevConfig();
    }
  } else if (env.isV1) {
    config = productionV1Config;
  }

  return config;
}

const config = getFirebaseConfig(environment);
// Initialize firebase instance
const firebaseApp: FirebaseApp = initializeApp(config);
const auth: Auth = getAuth(firebaseApp);
const firestore: Firestore = getFirestore(firebaseApp);
const storage: FirebaseStorage = getStorage(firebaseApp);
const defaultBucket: FirebaseStorage = getStorage(firebaseApp, config.storageBucket);

const profilePicsBucket: FirebaseStorage = getStorage(
  firebaseApp,
  config.profilePicsBucket
  // process.env.REACT_APP_FLYID_PROFILE_PICS_BUCKET
);

if (environment.usingEmulators) {
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  // connectStorageEmulator(profilePicsBucket, '127.0.0.1', 9199);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099');
}

// Get firebase references for store
const initFirebase = () => ({
  firebaseApp,
  auth,
  firestore,
  storage,
  defaultBucket,
  profilePicsBucket
});

export { auth, defaultBucket, firebaseApp, firestore, initFirebase, profilePicsBucket, storage };
