import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Typography
} from '@mui/material';
import { FirestoreError, getDocs, query } from 'firebase/firestore';
import {
  isSapRfcCallSettings,
  isWebServicePushSettings
} from 'flyid-core/dist/Database/Models/CompanySettings';
import {
  AuthenticationMethod,
  isBasicAuth,
  isBearerTokenAuth,
  isCustomAuth,
  isOAuth2
} from 'flyid-core/dist/Database/Models/Settings/APIAuthentication';
import {
  getCompaniesCol,
  getCustomAuthenticationDoc,
  getCustomPushSettingsDoc,
  getDomainsCol
} from 'flyid-core/dist/Util/database';
import useFirst from 'flyid-ui-components/src/hooks/useFirst';
import React, { FormEvent, useEffect, useState } from 'react';
import { useCollection, useCollectionOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { buildCollectionRef, buildDocumentRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { SetupInterfaceSettingsParams } from 'src/redux/actions/companyActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import LoadingButton from '../widgets/LoadingButton';
import LoadingCircle from '../widgets/LoadingCircle';
import HeadersManager from '../utils/HeadersManager';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(50),
    maxWidth: spacing(70)
  },
  titleContainer: {
    maxWidth: spacing(80)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  }
}));

const initialPushSettings = {
  flattenOutput: false,
  noBaseFields: false,
  useInputOrder: false,
  useCustomTransformer: false
};

const initialAuthSettings = {
  basicAuth: {
    credentials: {
      username: '',
      password: ''
    },
    getTokenUrl: ''
  },
  bearerToken: '',
  domainSpecificBearerToken: {} as Record<string, string>,
  tokenType: 'global',
  oauth2: {
    credentials: {
      username: '',
      password: ''
    },
    credentialsThrough: 'header',
    refreshTokenUrl: '',
    getTokenUrl: ''
  },
  authHeaders: {} as Record<string, string>,
  certificate: ''
};

const initialWebServiceSettings = {
  pushUrl: '',
  deleteOnPush: false,
  headers: {} as Record<string, string>,
  isTriggerActive: false,
  activeDomains: [] as string[]
};

const initialSapRfcSettings = {
  connParams: {
    user: '',
    passwd: '',
    ashost: '',
    sysnr: '',
    client: '',
    lang: ''
  },
  targetRFC: '',
  options: {
    retry: '0',
    deleteOnPush: false
  },
  isTriggerActive: false,
  activeDomains: [] as string[]
};

const clientTypes = [
  { value: 'webservice', label: 'Web Service' },
  { value: 'sap', label: 'SAP RFC Connector' }
];

type State = typeof initialPushSettings &
  typeof initialAuthSettings &
  typeof initialWebServiceSettings &
  typeof initialSapRfcSettings & {
    clientType: 'webservice' | 'sap';
  };

const initialDomainsData = {
  domainsState: undefined as Record<string, unknown> | undefined,
  loadingDomainsState: false,
  errorDomainsState: undefined as Error | undefined
};

type DomainsState = typeof initialDomainsData;

const InterfaceSettings: React.FC = () => {
  const classes = useStyles();
  const { spacing, text, select } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { ui } = useAppSelector((s) => ({
    ui: s.ui
  }));

  const [state, setState] = useState<State>({
    ...initialPushSettings,
    ...initialAuthSettings,
    ...initialWebServiceSettings,
    ...initialSapRfcSettings,
    clientType: 'webservice'
  });

  const [companySelected, setCompanySelected] = useState<string>('');
  const initialCompany = useFirst(companySelected);
  const [includeHeaders, setIncludeHeaders] = useState(false);
  const [includeActiveDomains, setIncludeActiveDomains] = useState(false);
  const [includeAuthParams, setIncludeAuthParams] = useState(false);
  const [authMethod, setAuthMethod] = useState<string>('');
  const [optionalAuthParams, setOptionalAuthParams] = useState(false);

  const [customPushSettings, loadingCustomPushSettings, errorCustomPushSettings] = useDocumentData(
    companySelected ? buildDocumentRef(getCustomPushSettingsDoc(companySelected)) : undefined
  );

  const [customAuthSettings, loadingCustomAuthSettings, errorCustomAuthSettings] = useDocumentData(
    companySelected ? buildDocumentRef(getCustomAuthenticationDoc(companySelected)) : undefined
  );

  const [{ domainsState, loadingDomainsState, errorDomainsState }, setActiveDomainsState] =
    useStateReducer<DomainsState>(Object.assign({}, initialDomainsData));

  const [companiesQS, loadingCompanies, errorCompanies] = useCollectionOnce(
    buildCollectionRef(getCompaniesCol())
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [domainsQS, loadingDomains, errorDomains] = useCollection(
    companySelected ? query(buildCollectionRef(getDomainsCol(companySelected))) : undefined
  );
  const actvDomains = querySnapToMap(domainsQS) ?? {};

  const combinedDomainsLoading = loadingDomains || loadingDomainsState;
  const domainsLoaded = !combinedDomainsLoading && !!domainsState && !!actvDomains;
  const domainsError = errorDomainsState || errorDomains;

  const isLoaded = !loadingCustomPushSettings && !loadingCustomAuthSettings && domainsLoaded;
  const isError =
    errorCustomPushSettings ||
    errorCustomAuthSettings ||
    domainsError ||
    errorCompanies ||
    errorDomains;

  useEffect(() => {
    if (!companySelected) return;

    // Updates the domains related to the selected company
    setActiveDomainsState({ loadingDomainsState: true });

    getDocs(query(buildCollectionRef(getDomainsCol(companySelected))))
      .then((actvDomainsQS) => {
        setActiveDomainsState({
          domainsState: querySnapToMap(actvDomainsQS),
          loadingDomainsState: false,
          errorDomainsState: undefined
        });
      })
      .catch((err) => {
        setActiveDomainsState({
          domainsState: undefined,
          loadingDomainsState: false,
          errorDomainsState: err as FirestoreError
        });
      });

    if (companySelected !== initialCompany) {
      if (customPushSettings) {
        applyCustomPushSettings();
      } else {
        resetConfigurations(false);
      }

      if (customAuthSettings) {
        applyCustomAuthSettings();
      } else {
        resetConfigurations(true);
      }
    } else {
      if (customPushSettings && domainsState) {
        applyCustomPushSettings();
      } else {
        resetConfigurations(false);
      }

      if (customAuthSettings && domainsState) {
        applyCustomAuthSettings();
      } else {
        resetConfigurations(true);
      }
    }
  }, [companySelected, customPushSettings, customAuthSettings, initialCompany]);

  const resetConfigurations = (hasAuthParams: boolean) => {
    if (hasAuthParams) {
      setState((prevState) => ({
        ...prevState,
        ...initialAuthSettings
      }));
      setIncludeAuthParams(false);
      setAuthMethod('');
    } else {
      setState({
        ...initialPushSettings,
        ...initialAuthSettings,
        ...initialWebServiceSettings,
        ...initialSapRfcSettings,
        clientType: 'webservice'
      });
      setIncludeHeaders(false);
      setIncludeActiveDomains(false);
      setIncludeAuthParams(false);
      setAuthMethod('');
    }
  };

  const applyCustomPushSettings = () => {
    if (customPushSettings) {
      setState((prevState) => {
        const hasHeaders =
          isWebServicePushSettings(customPushSettings) &&
          customPushSettings.headers &&
          Object.keys(customPushSettings.headers).length > 0;
        setIncludeHeaders(hasHeaders ?? false);

        const hasActiveDomains =
          Array.isArray(customPushSettings.activeDomains) &&
          customPushSettings.activeDomains.length > 0;
        setIncludeActiveDomains(hasActiveDomains ?? false);

        const isWebService = isWebServicePushSettings(customPushSettings);
        const isSapRfc = isSapRfcCallSettings(customPushSettings);

        return {
          ...prevState,
          // Commom PushSettings Params
          activeDomains: customPushSettings.activeDomains ?? [],
          isTriggerActive: customPushSettings.isTriggerActive ?? false,
          flattenOutput:
            'flattenOutput' in customPushSettings
              ? customPushSettings.flattenOutput
              : initialPushSettings.flattenOutput,
          noBaseFields:
            'noBaseFields' in customPushSettings
              ? customPushSettings.noBaseFields
              : initialPushSettings.noBaseFields,
          useInputOrder:
            'useInputOrder' in customPushSettings
              ? customPushSettings.useInputOrder
              : initialPushSettings.useInputOrder,
          useCustomTransformer:
            'useCustomTransformer' in customPushSettings
              ? customPushSettings.useCustomTransformer
              : initialPushSettings.useCustomTransformer,
          clientType: isSapRfc ? 'sap' : isWebService ? 'webservice' : prevState.clientType,
          // WebService Params
          pushUrl: isWebService ? customPushSettings.pushUrl : initialWebServiceSettings.pushUrl,
          deleteOnPush: isWebService
            ? customPushSettings.deleteOnPush
            : initialWebServiceSettings.deleteOnPush,
          headers: isWebService
            ? customPushSettings.headers ?? initialWebServiceSettings.headers
            : initialWebServiceSettings.headers,
          // SAP RFC Params
          connParams: isSapRfc
            ? {
                user: customPushSettings.connParams.user ?? initialSapRfcSettings.connParams.user,
                passwd:
                  customPushSettings.connParams.passwd ?? initialSapRfcSettings.connParams.passwd,
                ashost:
                  customPushSettings.connParams.ashost ?? initialSapRfcSettings.connParams.ashost,
                sysnr:
                  customPushSettings.connParams.sysnr ?? initialSapRfcSettings.connParams.sysnr,
                client:
                  customPushSettings.connParams.client ?? initialSapRfcSettings.connParams.client,
                lang: customPushSettings.connParams.lang ?? initialSapRfcSettings.connParams.lang
              }
            : initialSapRfcSettings.connParams,
          targetRFC: isSapRfc ? customPushSettings.targetRFC : initialSapRfcSettings.targetRFC,
          options: {
            retry:
              isSapRfc &&
              'options' in customPushSettings &&
              customPushSettings.options?.retry !== undefined
                ? String(customPushSettings.options.retry)
                : String(initialSapRfcSettings.options.retry),
            deleteOnPush:
              isSapRfc && customPushSettings.options?.deleteOnPush !== undefined
                ? customPushSettings.options.deleteOnPush
                : initialSapRfcSettings.options.deleteOnPush
          }
        };
      });
    }
  };

  const applyCustomAuthSettings = () => {
    if (customAuthSettings) {
      setState((prevState) => {
        setIncludeAuthParams(true);

        const certificate = Array.isArray(customAuthSettings.certificate)
          ? customAuthSettings.certificate.join(', ')
          : customAuthSettings.certificate ?? '';

        const authHeaders = customAuthSettings.headers ?? {};

        if (customAuthSettings.certificate || Object.keys(authHeaders).length > 0) {
          setOptionalAuthParams(true);
        }

        if (isBearerTokenAuth(customAuthSettings)) {
          setAuthMethod(customAuthSettings.method);

          const domainSpecificBearerToken = customAuthSettings.domainSpecificBearerToken
            ? Object.fromEntries(
                Object.entries(customAuthSettings.domainSpecificBearerToken).map(([key, value]) => [
                  key,
                  value ?? ''
                ])
              )
            : {};

          const bearerToken = customAuthSettings.bearerToken ?? '';

          const tokenType =
            domainSpecificBearerToken && Object.keys(domainSpecificBearerToken).length > 0
              ? 'domainSpecific'
              : bearerToken
                ? 'global'
                : '';

          return {
            ...prevState,
            tokenType,
            bearerToken,
            domainSpecificBearerToken,
            authHeaders,
            certificate
          };
        }
        if (isCustomAuth(customAuthSettings)) {
          setAuthMethod(customAuthSettings.method);
          return {
            ...prevState,
            authHeaders,
            certificate
          };
        }
        if (isBasicAuth(customAuthSettings)) {
          setAuthMethod(customAuthSettings.method);
          return {
            ...prevState,
            basicAuth: {
              credentials: {
                username: customAuthSettings.credentials.username,
                password: customAuthSettings.credentials.password
              },
              getTokenUrl: customAuthSettings.getTokenUrl ?? ''
            },
            authHeaders,
            certificate
          };
        }
        if (isOAuth2(customAuthSettings)) {
          setAuthMethod(customAuthSettings.method);
          return {
            ...prevState,
            oauth2: {
              credentials: {
                username: customAuthSettings.credentials.username,
                password: customAuthSettings.credentials.password
              },
              credentialsThrough: customAuthSettings.credentialsThrough ?? '',
              refreshTokenUrl: customAuthSettings.refreshTokenUrl ?? '',
              getTokenUrl: customAuthSettings.getTokenUrl ?? ''
            },
            authHeaders,
            certificate
          };
        }
        return prevState;
      });
    }
  };

  const renderClientSpecificFields = () => {
    if (state.clientType === 'webservice') {
      return (
        <>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Push URL"
              name="pushUrl"
              value={state.pushUrl}
              onChange={(e) =>
                setState({
                  ...state,
                  pushUrl: e.target.value
                })
              }
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.deleteOnPush}
                  name="deleteOnPush"
                  onChange={handleCheckboxChange}
                />
              }
              label={<Typography variant="body2">{$t({ id: 'admin.deleteOnPush' })}</Typography>}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.isTriggerActive}
                  name="isTriggerActive"
                  onChange={handleCheckboxChange}
                />
              }
              label={<Typography variant="body2">{$t({ id: 'admin.isTriggerActive' })}</Typography>}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeHeaders}
                  name="includeHeaders"
                  onChange={(e) => setIncludeHeaders(e.target.checked)}
                />
              }
              label={
                <Typography variant="body2">
                  {$t({ id: 'admin.includAdditionalHeaders' })}
                </Typography>
              }
            />
          </Grid>

          {includeHeaders && (
            <HeadersManager
              headers={state.headers}
              onHeadersChange={(updatedHeaders) =>
                setState((prevState) => ({ ...prevState, headers: updatedHeaders }))
              }
              headerNameLabel="Header Name"
              headerValueLabel="Header Value"
              addButtonLabel={$t({ id: 'admin.addHeaders' })}
            />
          )}
        </>
      );
    } else if (state.clientType === 'sap') {
      return (
        <>
          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="User"
              name="user"
              value={state.connParams.user}
              onChange={handleConnParamsChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Password"
              name="passwd"
              type="password"
              value={state.connParams.passwd}
              onChange={handleConnParamsChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Application Server Host (ashost)"
              name="ashost"
              value={state.connParams.ashost}
              onChange={handleConnParamsChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="System Number (sysnr)"
              name="sysnr"
              type="number"
              placeholder="0"
              value={state.connParams.sysnr}
              onChange={handleConnParamsChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Client"
              name="client"
              type="number"
              placeholder="0"
              value={state.connParams.client}
              onChange={handleConnParamsChange}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              required
              fullWidth
              label="Language (lang)"
              name="lang"
              placeholder="EN"
              value={state.connParams.lang}
              onChange={handleConnParamsChange}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              label="Target RFC"
              name="targetRFC"
              value={state.targetRFC || ''}
              onChange={handleTextChange}
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Retry"
              name="retry"
              type="number"
              placeholder="0 (optional)"
              value={state.options.retry || ''}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  options: {
                    ...prevState.options,
                    retry: e.target.value
                  }
                }))
              }
            />
          </Grid>

          <Grid item xs={5}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ ml: spacing(3) }}
                  checked={state.options.deleteOnPush}
                  name="deleteOnPush"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setState((prevState) => ({
                      ...prevState,
                      options: {
                        ...prevState.options,
                        [event.target.name]: event.target.checked
                      }
                    }));
                  }}
                />
              }
              label={<Typography variant="body2">{$t({ id: 'admin.deleteOnPush' })}</Typography>}
            />
          </Grid>

          <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.isTriggerActive}
                  name="isTriggerActive"
                  onChange={handleCheckboxChange}
                />
              }
              label={<Typography variant="body2">{$t({ id: 'admin.isTriggerActive' })}</Typography>}
            />
          </Grid>
        </>
      );
    }
    return null;
  };

  const buildAuthentication = (): AuthenticationMethod | null => {
    const baseAuth = {
      headers: state.authHeaders || {},
      certificate: state.certificate || ''
    };

    if (authMethod === 'basic_auth_sap' || authMethod === 'basic_auth') {
      return {
        ...baseAuth,
        method: state.clientType === 'sap' ? 'basic_auth_sap' : 'basic_auth',
        credentials: {
          username: state.basicAuth.credentials.username,
          password: state.basicAuth.credentials.password
        },
        getTokenUrl: state.basicAuth.getTokenUrl || undefined
      };
    } else if (authMethod === 'bearer_token') {
      if (state.tokenType === 'domainSpecific' && state.domainSpecificBearerToken) {
        return {
          ...baseAuth,
          method: 'bearer_token',
          domainSpecificBearerToken: state.domainSpecificBearerToken
        };
      } else if (state.tokenType === 'global' && state.bearerToken) {
        return {
          ...baseAuth,
          method: 'bearer_token',
          bearerToken: state.bearerToken
        };
      }
      return null;
    } else if (authMethod === 'custom') {
      return {
        ...baseAuth,
        method: 'custom'
      };
    } else if (authMethod === 'oauth2_get_token' || authMethod === 'oauth2_refresh_token') {
      return {
        ...baseAuth,
        method: authMethod,
        credentials: {
          username: state.oauth2.credentials.username,
          password: state.oauth2.credentials.password
        },
        credentialsThrough: state.oauth2.credentialsThrough as 'header' | 'body',
        refreshTokenUrl: state.oauth2.refreshTokenUrl || '',
        getTokenUrl: state.oauth2.getTokenUrl || ''
      };
    }

    return null;
  };

  const handleCompanyChange = (e: SelectChangeEvent<string>) => {
    setCompanySelected(e.target.value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked
    }));
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleMultipleSelectChange = (e: SelectChangeEvent<string[]>) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleConnParamsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      connParams: {
        ...prevState.connParams,
        [name]: value
      }
    }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    let data: SetupInterfaceSettingsParams;

    const authentication = buildAuthentication();

    if (state.clientType === 'webservice') {
      data = {
        company: companySelected,
        clientType: state.clientType,
        flattenOutput: state.flattenOutput,
        noBaseFields: state.noBaseFields,
        useInputOrder: state.useInputOrder,
        useCustomTransformer: state.useCustomTransformer,
        pushUrl: state.pushUrl,
        deleteOnPush: state.deleteOnPush,
        isTriggerActive: state.isTriggerActive,
        activeDomains: state.activeDomains,
        headers: state.headers,
        authentication: includeAuthParams && authentication ? [authentication] : undefined
      };
    } else if (state.clientType === 'sap') {
      data = {
        company: companySelected,
        clientType: state.clientType,
        flattenOutput: state.flattenOutput,
        noBaseFields: state.noBaseFields,
        useInputOrder: state.useInputOrder,
        useCustomTransformer: state.useCustomTransformer,
        isTriggerActive: state.isTriggerActive,
        activeDomains: state.activeDomains,
        connParams: {
          user: state.connParams.user,
          passwd: state.connParams.passwd,
          ashost: state.connParams.ashost,
          sysnr: state.connParams.sysnr,
          client: state.connParams.client,
          lang: state.connParams.lang
        },
        targetRFC: state.targetRFC,
        options: {
          retry: String(state.options.retry)
        },
        deleteOnPush: state.options.deleteOnPush,
        authentication: includeAuthParams && authentication ? [authentication] : undefined
      };
    } else {
      return;
    }

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.interfaceSettingsConfTitle' }),
          message: $t(
            { id: 'admin.interfaceSettingsConfSubtitle' },
            { company: <b key={`mdb0${companySelected}`}>{companySelected}</b> }
          ),
          show: true
        }).setConfirmAction(Actions.SETUP_INTERFACE_SETTINGS, data)
      })
    );
  };

  return (
    <>
      {!isError ? (
        <form onSubmit={handleSubmit}>
          <Container className={classes.container}>
            {!loadingCompanies && !!companies ? (
              <>
                <Grid container className={classes.titleContainer}>
                  <Typography variant="h4" sx={text.title}>
                    {$t({ id: 'admin.interfaceSettings' })}
                  </Typography>
                  <Typography variant="subtitle1" sx={text.subtitle}>
                    {$t({ id: 'admin.interfaceSettingsSubtitle' })}
                  </Typography>
                </Grid>

                <Grid container spacing={1} className={classes.mainGrid}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="company-select-label">
                        {$t({ id: 'admin.company' })}
                      </InputLabel>
                      <Select
                        required
                        labelId="company-select-label"
                        id="company-select"
                        name="company"
                        className={classes.margin}
                        value={companySelected}
                        onChange={handleCompanyChange}
                        input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                      >
                        <MenuItem value={''}>
                          <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                        </MenuItem>
                        {Object.keys(companies).map((value, index) => (
                          <MenuItem key={index} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid container className={classes.titleContainer}>
                  <Grid item xs={12}>
                    <Skeleton
                      variant="text"
                      height={spacing(10)}
                      width={spacing(80)}
                      animation="wave"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Skeleton
                      variant="text"
                      height={spacing(5)}
                      animation="wave"
                      sx={{ mb: spacing(3) }}
                    />
                  </Grid>
                </Grid>

                <Grid container className={classes.mainGrid}>
                  <Grid item xs={12}>
                    <Skeleton variant="rounded" animation="wave" height={spacing(7)} />
                  </Grid>
                </Grid>
              </>
            )}

            <Grid
              container
              item
              spacing={1}
              xs={12}
              alignContent="flex-start"
              className={classes.mainGrid}
            >
              {companySelected ? (
                isLoaded ? (
                  <>
                    <Grid item xs={12}>
                      <Typography variant="h6">{$t({ id: 'admin.pushSettings' })}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.flattenOutput}
                            name="flattenOutput"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.flattenOutput' })}
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.noBaseFields}
                            name="noBaseFields"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.noBaseFields' })}
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.useInputOrder}
                            name="useInputOrder"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.useInputOrder' })}
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.useCustomTransformer}
                            name="useCustomTransformer"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.useCustomTransformer' })}
                          </Typography>
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="client-type-select-label">Client Type</InputLabel>
                        <Select
                          labelId="client-type-select-label"
                          id="client-type-select"
                          sx={{ mb: spacing(0.5), mt: spacing(1) }}
                          value={state.clientType}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              clientType: e.target.value as 'webservice' | 'sap'
                            }))
                          }
                          input={<OutlinedInput label={'Client Type'} />}
                        >
                          {clientTypes.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {isLoaded ? renderClientSpecificFields() : null}

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeActiveDomains}
                            name="includeActiveDomains"
                            onChange={(e) => setIncludeActiveDomains(e.target.checked)}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.includActiveDomains' })}
                          </Typography>
                        }
                      />
                    </Grid>

                    {includeActiveDomains && (
                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <InputLabel id="activeDomains-label">
                            {$t({ id: 'admin.activeDomains' })}
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="activeDomains-label"
                            id="activeDomains"
                            name="activeDomains"
                            multiple
                            value={state.activeDomains}
                            onChange={handleMultipleSelectChange}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: spacing(0.5) }}>
                                {selected.map((domain) => (
                                  <Chip
                                    key={`rend${domain}`}
                                    label={domain}
                                    className={classes.chip}
                                  />
                                ))}
                              </Box>
                            )}
                            input={<OutlinedInput label={$t({ id: 'admin.activeDomains' })} />}
                            MenuProps={select.getMenuProps()}
                          >
                            {Object.keys(actvDomains).map((domain) => (
                              <MenuItem key={`men${domain}`} value={domain}>
                                <Checkbox checked={state.activeDomains.indexOf(domain) > -1} />
                                <ListItemText primary={domain} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={includeAuthParams}
                            name="includeAuthParams"
                            onChange={(e) => setIncludeAuthParams(e.target.checked)}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.setAuthParams' })}
                          </Typography>
                        }
                      />
                    </Grid>

                    {/* Authentication Method Selection */}
                    {includeAuthParams && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel id="auth-method-label">
                              {$t({ id: 'admin.authMethod' })}
                            </InputLabel>
                            <Select
                              labelId="auth-method-label"
                              id="auth-method-select"
                              value={authMethod}
                              onChange={(e) => setAuthMethod(e.target.value)}
                              label={$t({ id: 'admin.authMethod' })}
                            >
                              {state.clientType === 'sap' ? (
                                <MenuItem value="basic_auth_sap">Basic Authentication SAP</MenuItem>
                              ) : (
                                <MenuItem value="basic_auth">Basic Authentication</MenuItem>
                              )}
                              <MenuItem value="bearer_token">Bearer Token</MenuItem>
                              <MenuItem value="custom">Custom Authentication</MenuItem>
                              <MenuItem value="oauth2_get_token">OAuth2 (Get Token)</MenuItem>
                              <MenuItem value="oauth2_refresh_token">
                                OAuth2 (Refresh Token)
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* Render fields based on selected authentication method */}
                        {authMethod === 'basic_auth' ||
                          (authMethod === 'basic_auth_sap' && (
                            <>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  required
                                  label={$t({ id: 'user' })}
                                  value={state.basicAuth.credentials.username}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      basicAuth: {
                                        ...state.basicAuth,
                                        credentials: {
                                          ...state.basicAuth.credentials,
                                          username: e.target.value
                                        }
                                      }
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  fullWidth
                                  required
                                  label={$t({ id: 'password' })}
                                  type="password"
                                  value={state.basicAuth.credentials.password}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      basicAuth: {
                                        ...state.basicAuth,
                                        credentials: {
                                          ...state.basicAuth.credentials,
                                          password: e.target.value
                                        }
                                      }
                                    })
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Token URL (optional)"
                                  value={state.basicAuth.getTokenUrl || ''}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      basicAuth: {
                                        ...state.basicAuth,
                                        getTokenUrl: e.target.value
                                      }
                                    })
                                  }
                                />
                              </Grid>
                            </>
                          ))}

                        {authMethod === 'bearer_token' && (
                          <>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <InputLabel id="token-type-label">Bearer Token Type</InputLabel>
                                <Select
                                  labelId="token-type-label"
                                  id="token-type-select"
                                  value={state.tokenType}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      tokenType: e.target.value
                                    })
                                  }
                                  label="Bearer Token Type"
                                >
                                  <MenuItem value="global">Global Token</MenuItem>
                                  <MenuItem value="domainSpecific">Domain-Specific Tokens</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            {/* Render fields based on token type selection */}
                            {authMethod === 'bearer_token' && (
                              <>
                                {/* Global Bearer Token */}
                                {state.tokenType === 'global' && (
                                  <Grid item xs={12}>
                                    <TextField
                                      fullWidth
                                      required
                                      label="Bearer Token"
                                      value={state.bearerToken}
                                      onChange={(e) =>
                                        setState({ ...state, bearerToken: e.target.value })
                                      }
                                    />
                                  </Grid>
                                )}

                                {/* Domain-Specific Bearer Tokens */}
                                {state.tokenType === 'domainSpecific' && (
                                  <Grid item xs={12}>
                                    {Object.entries(actvDomains).map(([domainId]) => (
                                      <Grid
                                        container
                                        item
                                        key={domainId}
                                        alignItems="center"
                                        spacing={1}
                                        sx={{ mt: spacing(1) }}
                                      >
                                        <Grid item xs={4}>
                                          <Typography>{domainId}</Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                          <TextField
                                            fullWidth
                                            label={`Token for ${domainId}`}
                                            value={state.domainSpecificBearerToken[domainId] || ''}
                                            onChange={(e) => {
                                              const newDomainSpecificTokens = {
                                                ...state.domainSpecificBearerToken,
                                                [domainId]: e.target.value
                                              };
                                              setState({
                                                ...state,
                                                domainSpecificBearerToken: newDomainSpecificTokens
                                              });
                                            }}
                                          />
                                        </Grid>
                                        <Grid item xs={2}>
                                          <Button
                                            variant="contained"
                                            sx={{ ml: spacing(2) }}
                                            color="error"
                                            onClick={() => {
                                              const newDomainSpecificTokens = {
                                                ...state.domainSpecificBearerToken
                                              };
                                              delete newDomainSpecificTokens[domainId];
                                              setState({
                                                ...state,
                                                domainSpecificBearerToken: newDomainSpecificTokens
                                              });
                                            }}
                                          >
                                            <DeleteIcon />
                                          </Button>
                                        </Grid>
                                      </Grid>
                                    ))}
                                  </Grid>
                                )}
                              </>
                            )}
                          </>
                        )}

                        {(authMethod === 'oauth2_get_token' ||
                          authMethod === 'oauth2_refresh_token') && (
                          <>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                required
                                label={$t({ id: 'user' })}
                                value={state.oauth2?.credentials?.username || ''}
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    oauth2: {
                                      ...state.oauth2,
                                      credentials: {
                                        ...state.oauth2.credentials,
                                        username: e.target.value
                                      }
                                    }
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                fullWidth
                                required
                                label={$t({ id: 'password' })}
                                type="password"
                                value={state.oauth2.credentials.password}
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    oauth2: {
                                      ...state.oauth2,
                                      credentials: {
                                        ...state.oauth2.credentials,
                                        password: e.target.value
                                      }
                                    }
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl fullWidth>
                                <InputLabel id="credentials-through-label">
                                  {$t({ id: 'admin.credentialsThrough' })}
                                </InputLabel>
                                <Select
                                  labelId="credentials-through-label"
                                  id="credentials-through-select"
                                  value={state.oauth2.credentialsThrough}
                                  onChange={(e) =>
                                    setState({
                                      ...state,
                                      oauth2: {
                                        ...state.oauth2,
                                        credentialsThrough: e.target.value
                                      }
                                    })
                                  }
                                  label={$t({ id: 'admin.credentialsThrough' })}
                                >
                                  <MenuItem value="header">Header</MenuItem>
                                  <MenuItem value="body">Body</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Token URL (optional)"
                                value={state.oauth2.getTokenUrl || ''}
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    oauth2: {
                                      ...state.oauth2,
                                      getTokenUrl: e.target.value
                                    }
                                  })
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label="Refresh Token URL (optional)"
                                value={state.oauth2.refreshTokenUrl}
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    oauth2: {
                                      ...state.oauth2,
                                      refreshTokenUrl: e.target.value
                                    }
                                  })
                                }
                              />
                            </Grid>
                          </>
                        )}

                        {isLoaded && authMethod !== '' ? (
                          <>
                            <Grid item xs={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={optionalAuthParams}
                                    name="optionalAuthParams"
                                    onChange={(e) => setOptionalAuthParams(e.target.checked)}
                                  />
                                }
                                label={
                                  <Typography variant="body2">
                                    {$t({ id: 'admin.setOptAuthParams' })}
                                  </Typography>
                                }
                              />
                            </Grid>
                          </>
                        ) : null}

                        {optionalAuthParams && (
                          <>
                            <HeadersManager
                              headers={state.authHeaders}
                              onHeadersChange={(updatedHeaders) =>
                                setState((prevState) => ({
                                  ...prevState,
                                  authHeaders: updatedHeaders
                                }))
                              }
                              headerNameLabel="Auth Header Name"
                              headerValueLabel="Auth Header Value"
                              addButtonLabel={$t({ id: 'admin.addAuthHeaders' })}
                            />

                            <Grid item xs={12}>
                              <TextField
                                fullWidth
                                label={$t({ id: 'admin.certificateOpt' })}
                                value={state.certificate || ''}
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    certificate: e.target.value
                                  })
                                }
                              />
                            </Grid>
                          </>
                        )}
                      </>
                    )}

                    {/* Save changes button */}
                    <Grid item xs={12}>
                      <LoadingButton
                        content={$t({ id: 'saveChanges' })}
                        type="submit"
                        className={classes.button}
                        isLoading={ui.loadingButton.isCompanyActionLoading}
                      />
                    </Grid>
                  </>
                ) : (
                  <LoadingCircle />
                )
              ) : null}
            </Grid>
          </Container>
        </form>
      ) : (
        <Typography variant="body1">An error occurred: {isError.message}</Typography>
      )}
    </>
  );
};

export default InterfaceSettings;
