import { MenuProps } from '@mui/material';
import { enUS, esES, Localization, ptBR } from '@mui/material/locale';
import { createTheme, Theme } from '@mui/material/styles';
import { makeStyles, useTheme } from '@mui/styles';
import { pidExtraTheme, pidPalette } from 'flyid-core/dist/Theme';
import { SupportedLocalesType } from 'src/util/locale';

const extraTheme = {
  ...pidExtraTheme,
  form: {
    maxWidth: '768px'
  }
};
type ExtraThemeType = typeof extraTheme;

const theme = createTheme({
  // Add palette (overrides)
  palette: pidPalette,
  // Override component props
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          // Do not allow button to wrap text
          whiteSpace: 'nowrap',
          minWidth: 'min-content'
        }
      }
    }
  },
  // Add non-overriding themes
  ...extraTheme
}) as Theme & ExtraThemeType;

const resizableContainer = (horizontalPadding: number, verticalPadding = 2) => ({
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(
      2 * verticalPadding,
      7 * horizontalPadding,
      2 * verticalPadding,
      7 * horizontalPadding
    )
  },
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(
      1.5 * verticalPadding,
      5 * horizontalPadding,
      1.5 * verticalPadding,
      5 * horizontalPadding
    )
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(
      verticalPadding,
      2 * horizontalPadding,
      verticalPadding,
      2 * horizontalPadding
    )
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(verticalPadding, horizontalPadding, verticalPadding, horizontalPadding)
  }
});

const text = {
  title: {
    flex: '1 1 100%',
    color: theme.other.grey.dark,
    marginBottom: theme.spacing(2)
  },
  subtitle: {
    marginBottom: theme.spacing(4)
  },
  tableTitle: {
    flex: '1 1 100%',
    fontWeight: 'bolder',
    color: theme.other.grey.dark
  }
};

const SELECT_ITEM_HEIGHT = 48;
const SELECT_ITEM_PADDING_TOP = 8;
const SELECT_MAX_ITEMS = 10;
const select = {
  getMenuProps: (extra?: Partial<MenuProps>): Partial<MenuProps> => ({
    PaperProps: {
      style: {
        maxHeight: SELECT_ITEM_HEIGHT * SELECT_MAX_ITEMS + SELECT_ITEM_PADDING_TOP
      }
    },
    ...extra
  }),
  inFormControl: {
    margin: theme.spacing(1, 1, 1, 0)
  }
};

const MuiLocales: Record<SupportedLocalesType, Localization> = {
  'en-GB': enUS,
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-ES': esES
};

const ThemeExtensions = {
  /** Spacing applied to correctly place content when drawer is showing */
  resizableContainer,
  /** Standardized styles for text */
  text,
  /** Standardized MUI Select styles */
  select
};
export type ThemeExtensionsType = typeof ThemeExtensions;

// Common for all nodes
const getTheme = (locale: SupportedLocalesType) => {
  return createTheme(theme, ThemeExtensions, MuiLocales[locale]);
};

export type AppTheme = Theme & ExtraThemeType & ThemeExtensionsType;

const appMakeStyles: typeof makeStyles<AppTheme> = (styles, options) =>
  makeStyles<AppTheme>(styles, options);

const useAppTheme = useTheme<AppTheme>;

export { appMakeStyles, useAppTheme };

export default getTheme;
