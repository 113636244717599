import { cx } from '@emotion/css';
import { Container, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { auth } from 'src/firebase/firebase';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { appMakeStyles } from 'src/theme/theme';
import { loginAction } from '../../redux/actions/userActions';
import LoadingButton from '../widgets/LoadingButton';
import LoadingCircle from '../widgets/LoadingCircle';

const useStyles = appMakeStyles(({ spacing, resizableContainer }) => ({
  container: { ...resizableContainer(4), marginTop: spacing(4) },
  margin: {
    marginBottom: spacing(2)
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}));

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const ui = useAppSelector(({ ui }) => ui);
  const { $t } = useIntl();

  const [user, authLoading] = useAuthState(auth);

  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(loginAction({ email, password }));
  };

  return authLoading ? (
    <LoadingCircle />
  ) : user ? (
    <Navigate to="/" />
  ) : (
    <Container className={classes.container}>
      <form onSubmit={handleSubmit}>
        <Typography variant="h4" className={cx(classes.margin, classes.title)}>
          {$t({ id: 'login' })}
        </Typography>
        <TextField
          fullWidth
          required
          id="email"
          name="email"
          type="email"
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={classes.margin}
          autoFocus
        />
        <TextField
          fullWidth
          required
          id="password"
          name="password"
          type="password"
          label={$t({ id: 'password' })}
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={classes.margin}
          inputProps={{ minLength: 6 }}
        />

        <div className={classes.buttonsContainer}>
          <LoadingButton
            isLoading={ui.loadingButton.isLoginLoading}
            content={$t({ id: 'submit' })}
            type="submit"
          />
        </div>
      </form>
    </Container>
  );
};

export default Login;
