import { Container, Typography } from '@mui/material';
import { appMakeStyles } from 'src/theme/theme';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'src/hooks/reduxHooks';

const useStyles = appMakeStyles(({ spacing, resizableContainer }) => ({
  container: { ...resizableContainer(2) },
  margin: {
    marginBottom: spacing(2)
  }
}));

const Main: React.FC = () => {
  const classes = useStyles();
  const profile = useAppSelector((state) => state.user.profile);

  return (
    <Container className={classes.container}>
      <Typography variant="h4" className={classes.margin}>
        <FormattedMessage
          id="main.title"
          values={{ firstName: profile.firstName, lastName: profile.lastName }}
        />
      </Typography>
      <Typography variant="subtitle1" className={classes.margin}>
        <FormattedMessage id="main.msg" />
      </Typography>
    </Container>
  );
};

export default Main;
