"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isOAuth2 = exports.isBasicAuth = exports.isCustomAuth = exports.isBearerTokenAuth = exports.isAuthMethod = void 0;
const isAuthMethod = (obj) => {
    const typed = obj;
    return !!typed?.method;
};
exports.isAuthMethod = isAuthMethod;
const isBearerTokenAuth = (authMethod) => (0, exports.isAuthMethod)(authMethod) && authMethod.method === 'bearer_token';
exports.isBearerTokenAuth = isBearerTokenAuth;
const isCustomAuth = (authMethod) => (0, exports.isAuthMethod)(authMethod) && authMethod.method.includes('custom');
exports.isCustomAuth = isCustomAuth;
const isBasicAuth = (authMethod) => (0, exports.isAuthMethod)(authMethod) && authMethod.method.includes('basic_auth');
exports.isBasicAuth = isBasicAuth;
const isOAuth2 = (authMethod) => (0, exports.isAuthMethod)(authMethod) && authMethod.method.includes('oauth2_');
exports.isOAuth2 = isOAuth2;
