import React from 'react';
import { Grid, TextField, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface HeadersManagerProps {
  headers: Record<string, string>;
  onHeadersChange: (updatedHeaders: Record<string, string>) => void;
  headerNameLabel: string;
  headerValueLabel: string;
  addButtonLabel: string;
}

const HeadersManager: React.FC<HeadersManagerProps> = ({
  headers,
  onHeadersChange,
  headerNameLabel,
  headerValueLabel,
  addButtonLabel
}) => {
  const handleHeaderNameChange = (oldKey: string, newKey: string) => {
    const updatedHeaders = { ...headers };
    updatedHeaders[newKey] = updatedHeaders[oldKey];
    delete updatedHeaders[oldKey];
    onHeadersChange(updatedHeaders);
  };

  const handleHeaderValueChange = (key: string, newValue: string) => {
    const updatedHeaders = { ...headers, [key]: newValue };
    onHeadersChange(updatedHeaders);
  };

  const handleDeleteHeader = (key: string) => {
    const updatedHeaders = { ...headers };
    delete updatedHeaders[key];
    onHeadersChange(updatedHeaders);
  };

  const handleAddHeader = () => {
    onHeadersChange({ ...headers, '': '' });
  };

  return (
    <Grid item xs={12}>
      {Object.entries(headers).map(([key, value], index) => (
        <Grid container spacing={1} key={index} sx={{ mb: 1 }}>
          <Grid item xs={5}>
            <TextField
              fullWidth
              label={headerNameLabel}
              value={key}
              onChange={(e) => handleHeaderNameChange(key, e.target.value)}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              label={headerValueLabel}
              value={value}
              onChange={(e) => handleHeaderValueChange(key, e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="error"
              sx={{ mt: 1 }}
              onClick={() => handleDeleteHeader(key)}
            >
              <DeleteIcon />
            </Button>
          </Grid>
        </Grid>
      ))}
      <Button color="primary" variant="contained" onClick={handleAddHeader} sx={{ mb: 1 }}>
        {addButtonLabel}
      </Button>
    </Grid>
  );
};

export default HeadersManager;
