import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Button,
  Checkbox,
  Chip,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import { FirestoreError, getDocs, query, where } from 'firebase/firestore';
import { UserPublic } from 'flyid-core/dist/Database/Models';
import {
  getCompaniesCol,
  getLicenseSettingsDoc,
  getOurLicenseDoc,
  getUsersCol
} from 'flyid-core/dist/Util/database';
import useFirst from 'flyid-ui-components/src/hooks/useFirst';
import React, { FormEvent, useEffect } from 'react';
import { useCollectionOnce, useDocumentData } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Link, Navigate, useParams } from 'react-router-dom';
import { buildCollectionRef, buildDocumentRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import useStateReducer from 'src/hooks/useStateReducer';
import { Actions } from 'src/redux/actions/actionTypes';
import { EditFlyidLicenseParams } from 'src/redux/actions/licenseActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import LoadingButton from '../widgets/LoadingButton';
// eslint-disable-next-line import/no-unassigned-import
import 'dayjs/locale/pt-br';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(70),
    maxWidth: spacing(70)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: spacing(0.5)
  }
}));

const initialLicenseData = {
  company: '',
  ownerId: '',
  expDate: dayjs(new Date()),
  authDomains: [] as string[],
  description: '',
  spotOnly: false,
  isOurHardware: false,
  isActive: false,
  demonstrationOnly: false,
  scanditLicenseKey: '',
  hasMatrixScan: false
};
type State = typeof initialLicenseData;

const initialOwnersData = {
  owners: undefined as Record<string, UserPublic> | undefined,
  loadingOwners: false,
  errorOwners: undefined as FirestoreError | undefined
};
type OwnersState = typeof initialOwnersData;

const EditLicense: React.FC = () => {
  const { licenseId } = useParams<LicenseMatchParams>();
  // Fallback to home if uid is missing
  if (!licenseId) return <Navigate replace to="/" />;

  const classes = useStyles();
  const { text, select, spacing } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const { ui } = useAppSelector((s) => ({
    ui: s.ui
  }));

  const [state, setState] = useStateReducer<State>(Object.assign({}, initialLicenseData));
  const initialCompany = useFirst(state.company);
  const initialOwner = useFirst(state.ownerId);

  const [license, loadingLicense, errorLicense] = useDocumentData(
    buildDocumentRef(getOurLicenseDoc(licenseId))
  );

  const [licenseSettings, loadingLicenseSettings, errorLicenseSettings] = useDocumentData(
    buildDocumentRef(getLicenseSettingsDoc(licenseId))
  );

  const combinedLicenseLoading = loadingLicense || loadingLicenseSettings;
  const licenseLoaded = !combinedLicenseLoading && !!license && !!licenseSettings;
  const errorsLicense = errorLicense || errorLicenseSettings;

  const [companiesQS, loadingCompanies, errorCompanies] = useCollectionOnce(
    buildCollectionRef(getCompaniesCol())
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [{ owners, loadingOwners, errorOwners }, setOwnersState] = useStateReducer<OwnersState>(
    Object.assign({}, initialOwnersData)
  );

  const [currentOwnersQS, loadingCurrentOwner, errorCurrentOwner] = useCollectionOnce(
    license
      ? query(
          buildCollectionRef(getUsersCol()),
          where('authLicenses', 'array-contains', licenseId),
          where('moderator', '==', true)
        )
      : undefined
  );
  const currentOwner = querySnapToMap(currentOwnersQS);

  const combinedOwnersLoading = loadingOwners || loadingCurrentOwner;
  const isOwnerLoaded = !combinedOwnersLoading && !!owners && !!currentOwner;
  const isLoaded = licenseLoaded && isOwnerLoaded && !loadingCompanies;
  const isError = errorsLicense || errorCompanies || errorOwners || errorCurrentOwner;

  const buildOwnerInfo = (ownerId: string | null) => {
    let userData = 'User data not found';

    if (isOwnerLoaded && ownerId) {
      const ownerData = currentOwner[ownerId] ?? owners[ownerId];
      if (ownerData) {
        userData = `${ownerData.firstName} ${ownerData.lastName} (${ownerId})`;
      }
    }
    return userData;
  };

  useEffect(() => {
    if (license) {
      if (currentOwner) {
        const ownerId = Object.keys(currentOwner)[0];

        setState({
          company: license.company,
          ownerId: ownerId,
          description: license.description,
          expDate: dayjs(new Date(license.expDate)),
          authDomains: [...license.authDomains],
          spotOnly: license.spotOnly,
          isOurHardware: license.isOurHardware,
          isActive: license.isActive,
          demonstrationOnly: license.demonstrationOnly,
          scanditLicenseKey: licenseSettings?.scanditApiKey,
          hasMatrixScan: licenseSettings?.hasMatrixScan
        });
      }
    }
  }, [license, licenseSettings, currentOwnersQS]);

  useEffect(() => {
    if (state.company) {
      setOwnersState({ loadingOwners: true });
      getDocs(
        query(
          buildCollectionRef(getUsersCol()),
          where('company', '==', state.company),
          where('moderator', '==', true)
        )
      )
        .then((ownersQS) => {
          setOwnersState({
            owners: querySnapToMap(ownersQS),
            loadingOwners: false,
            errorOwners: undefined
          });
        })
        .catch((err) => {
          setOwnersState({ loadingOwners: false, errorOwners: err as FirestoreError });
        });

      // If initial company has already been set, check for relevant UI changes
      if (initialCompany) {
        // If company changed to something other than initial, remove authDomains and owner
        if (state.company !== initialCompany) setState({ ownerId: '', authDomains: [] });
        // If company changed back to initial, recover initial authDomains and owner
        else if (state.company === initialCompany && license && currentOwner) {
          setState({ ownerId: initialOwner, authDomains: license.authDomains });
        }
      }
    }
  }, [state.company]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const ownerIdChanged = state.ownerId !== initialOwner;

    let licenseData: EditFlyidLicenseParams;
    if (ownerIdChanged && initialCompany) {
      licenseData = {
        license: licenseId,
        company: state.company,
        ownerId: state.ownerId,
        description: state.description,
        expDate: state.expDate.format('DD/MM/YYYY'),
        spotOnly: state.spotOnly,
        isOurHardware: state.isOurHardware,
        isActive: state.isActive,
        demonstrationOnly: state.demonstrationOnly,
        scanditLicenseKey: state.scanditLicenseKey,
        hasMatrixScan: state.hasMatrixScan,
        authDomains: state.authDomains
      };
    } else {
      licenseData = {
        license: licenseId,
        description: state.description,
        expDate: state.expDate.format('DD/MM/YYYY'),
        spotOnly: state.spotOnly,
        isOurHardware: state.isOurHardware,
        isActive: state.isActive,
        demonstrationOnly: state.demonstrationOnly,
        scanditLicenseKey: state.scanditLicenseKey,
        hasMatrixScan: state.hasMatrixScan,
        authDomains: state.authDomains
      };
    }

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'admin.editFlyidLicenseConfTitle' }),
          message: $t(
            { id: 'admin.editFlyidLicenseConfMsg' },
            { license: <b key={`mdb0${licenseId}`}>{licenseId}</b> }
          ),
          show: true
        }).setConfirmAction(Actions.EDIT_FLYID_LICENSE, licenseData)
      })
    );
  };

  const maxExpDate: Dayjs | undefined = state.demonstrationOnly
    ? dayjs().add(3, 'month')
    : dayjs('2222-12-31');

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setState({ [e.target.name]: e.target.checked });
  };

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState({ [e.target.name]: e.target.value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const key = e.target.name as keyof State;
    const changeEffects = {} as Partial<State>;
    if (key === 'ownerId') {
      changeEffects.authDomains = [];
    }
    setState({ [key]: e.target.value, ...changeEffects });
  };

  const handleMultipleSelectChange = (e: SelectChangeEvent<string[]>) => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <>
      {!isError ? (
        <form onSubmit={handleSubmit}>
          <Container className={classes.container}>
            {isLoaded ? (
              <>
                <Grid container spacing={2} className={classes.mainGrid}>
                  <Grid item xs={12}>
                    <Typography variant="h4" sx={text.title}>
                      {$t({ id: 'admin.editFlyidLicense' })}
                    </Typography>
                    <Typography variant="subtitle1" sx={text.subtitle}>
                      {$t(
                        { id: 'admin.editFlyidLicenseSubtitle' },
                        { license: <b key={`mdb0${licenseId}`}>{licenseId}</b> }
                      )}
                    </Typography>
                  </Grid>

                  {/* Company */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="company-select-label">
                        {$t({ id: 'admin.company' })}
                      </InputLabel>
                      <Select
                        labelId="company-select-label"
                        name="company"
                        onChange={handleSelectChange}
                        value={state.company ?? ''}
                        input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                      >
                        {Object.keys(companies).map((value, index) => {
                          return (
                            <MenuItem key={index} value={value}>
                              {value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Moderator */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="moderator-select-label">{$t({ id: 'moderator' })}</InputLabel>
                      <Select
                        labelId="moderator-select-label"
                        name="ownerId"
                        id="ownerId"
                        renderValue={
                          isOwnerLoaded ? (ownerId) => buildOwnerInfo(ownerId) : undefined
                        }
                        onChange={handleSelectChange}
                        value={isOwnerLoaded ? (state.ownerId ?? '') : ''}
                        input={<OutlinedInput label={$t({ id: 'moderator' })} />}
                      >
                        {owners &&
                          Object.entries(owners).map(([uid], index) => (
                            <MenuItem key={index} value={uid}>
                              {buildOwnerInfo(uid)}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* authDomains */}
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="authdomains-label">{$t({ id: 'authDomains' })}</InputLabel>
                      <Select
                        fullWidth
                        labelId="authdomains-label"
                        id="authdomains"
                        name="authDomains"
                        multiple
                        value={state.authDomains}
                        onChange={handleMultipleSelectChange}
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip key={`rend${value}`} label={value} className={classes.chip} />
                            ))}
                          </div>
                        )}
                        MenuProps={select.getMenuProps()}
                        input={<OutlinedInput label={$t({ id: 'authDomains' })} />}
                      >
                        {isOwnerLoaded ? (
                          owners[state.ownerId]?.authDomains.map((domain: string) => {
                            return (
                              <MenuItem key={domain} value={domain}>
                                <Checkbox checked={state.authDomains.indexOf(domain) > -1} />
                                <ListItemText primary={domain} />
                              </MenuItem>
                            );
                          })
                        ) : (
                          <MenuItem value={''} disabled>
                            <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                          </MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* Description */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="description"
                      name="description"
                      value={state.description}
                      type="text"
                      onChange={handleTextChange}
                      label={$t({ id: 'description' })}
                    />
                  </Grid>

                  {/* isOurHardware, isActive and demonstrationOnly */}
                  <Grid container item spacing={1} xs={12} alignContent="flex-start">
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isOurHardware}
                            name="isOurHardware"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.isOurHardware' })}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.isActive}
                            name="isActive"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">{$t({ id: 'admin.isActive' })}</Typography>
                        }
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.demonstrationOnly}
                            name="demonstrationOnly"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">
                            {$t({ id: 'admin.demonstrationOnly' })}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>

                  {/* Expiration Date */}
                  <Grid
                    container
                    item
                    spacing={10}
                    direction="row"
                    xs={12}
                    alignContent={'space-between'}
                  >
                    <Grid item xs={6}>
                      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <DatePicker
                          disablePast
                          label={$t({ id: 'expirationDate' })}
                          value={state.expDate}
                          onChange={(value) => setState({ expDate: value ?? undefined })}
                          maxDate={maxExpDate}
                        />
                      </LocalizationProvider>
                    </Grid>

                    {/* Spotonly */}
                    <Grid item xs={6} alignItems={''}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.spotOnly}
                            name="spotOnly"
                            onChange={handleCheckboxChange}
                          />
                        }
                        label={
                          <Typography variant="body2">{$t({ id: 'spotOnlyLicense' })}</Typography>
                        }
                      />
                      <Tooltip
                        disableTouchListener
                        title={
                          <Typography variant="subtitle2">
                            {$t({ id: 'admin.spotOnlyTooltip' })}
                          </Typography>
                        }
                      >
                        <HelpOutlineIcon fontSize="medium" sx={{ color: 'info.dark' }} />
                      </Tooltip>
                    </Grid>
                  </Grid>

                  {/* Scandit API Key and hasMatrixScan */}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="scanditLicenseKey"
                      name="scanditLicenseKey"
                      type="text"
                      label={$t({ id: 'scanditAPIKey' })}
                      value={state.scanditLicenseKey ?? ''}
                      onChange={handleTextChange}
                    />
                  </Grid>

                  <Grid item xs={5}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.hasMatrixScan ?? false}
                          name="hasMatrixScan"
                          onChange={handleCheckboxChange}
                        />
                      }
                      label={
                        <Typography variant="body2">{$t({ id: 'admin.hasMatrixScan' })}</Typography>
                      }
                    />
                  </Grid>

                  <Grid container item xs={12}>
                    <Grid item xs={8}>
                      <LoadingButton
                        content={$t({ id: 'saveChanges' })}
                        type="submit"
                        isLoading={ui.loadingButton.isLicenseActionLoading}
                      />
                      <Button
                        component={Link}
                        to={'/managelicenses'}
                        sx={{ ml: spacing(2), height: spacing(5) }}
                        color="error"
                        variant="contained"
                      >
                        {$t({ id: 'cancel' })}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  spacing={2}
                  className={classes.mainGrid}
                  alignContent={'flex-start'}
                >
                  <Grid item xs={12}>
                    <Skeleton variant="text" height={spacing(10)} animation="wave" />
                    <Skeleton variant="text" height={spacing(4)} animation="wave" />
                  </Grid>

                  {Array.from({ length: 3 }).map((_, index) => (
                    <Grid item xs={12} key={`skeleton-${index}`}>
                      <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <Skeleton variant="rounded" height={spacing(20)} animation="wave" />
                  </Grid>

                  <Grid item xs={12}>
                    <Skeleton variant="rounded" height={spacing(4)} animation="wave" />
                  </Grid>

                  <Grid item xs={6}>
                    <Skeleton variant="rounded" height={spacing(8)} animation="wave" />
                  </Grid>

                  <Grid item xs={5}>
                    <Skeleton
                      sx={{ mt: spacing(1) }}
                      variant="rounded"
                      height={spacing(6)}
                      animation="wave"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Skeleton variant="rounded" height={spacing(6)} animation="wave" />
                  </Grid>

                  <Grid container item xs={8} alignContent="flex-start">
                    <Grid item xs={4}>
                      <Skeleton variant="rounded" height={spacing(5)} animation="wave" />
                    </Grid>
                    <Grid item xs={4}>
                      <Skeleton
                        variant="rounded"
                        height={spacing(5)}
                        animation="wave"
                        sx={{ ml: spacing(2) }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Container>
        </form>
      ) : (
        <Typography variant="body1">An error occurred: {isError.message}</Typography>
      )}
    </>
  );
};

export default EditLicense;
