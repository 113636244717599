import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Tooltip,
  Typography
} from '@mui/material';
import { query, where } from 'firebase/firestore';
import { getCompaniesCol, getKeyUsersCol, getUsersCol } from 'flyid-core/dist/Util/database';
import { isEmpty } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { buildCollectionRef, querySnapToMap } from 'src/firebase/firestore';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { Actions } from 'src/redux/actions/actionTypes';
import { fetchProfileImages } from 'src/redux/actions/userActions';
import { MyDialogState, updateUi } from 'src/redux/reducers/uiReducer';
import { appMakeStyles, useAppTheme } from 'src/theme/theme';
import { useStoredState } from 'src/util/web';
import noImg from '../../assets/images/avatar-no-img.png';

const useStyles = appMakeStyles(({ resizableContainer, spacing }) => ({
  container: {
    ...resizableContainer(2),
    marginLeft: 0
  },
  mainGrid: {
    minWidth: spacing(50),
    maxWidth: spacing(80)
  },
  titleContainer: {
    maxWidth: spacing(100)
  },
  margin: {
    marginBottom: spacing(1.5)
  },
  button: {
    marginTop: spacing(1)
  },
  profileImage: {
    marginTop: spacing(0.5),
    width: spacing(9),
    height: spacing(9)
  },
  itemText: {
    margin: spacing(1, 1),
    maxWidth: spacing(55),
    marginLeft: spacing(2)
  }
}));

const ManageUsers: React.FC = () => {
  const classes = useStyles();
  const { spacing, text, palette } = useAppTheme();
  const { $t } = useIntl();
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.user);

  const [companySelected, setCompanySelected] = useStoredState<string>('companySelect', '');

  const [openParent, setOpenParent] = useState<string | null>(null);

  const [companiesQS, loadingCompanies, errorCompanies] = useCollection(
    buildCollectionRef(getCompaniesCol())
  );
  const companies = querySnapToMap(companiesQS) ?? {};

  const [usersQS, loadingUsers, errorUsers] = useCollection(
    companySelected
      ? query(buildCollectionRef(getUsersCol()), where('company', '==', companySelected))
      : undefined
  );
  const users = querySnapToMap(usersQS) ?? {};

  const [keyUsersQS, loadingkeyUsers, errorkeyUsers] = useCollection(
    companySelected
      ? query(
          buildCollectionRef(getKeyUsersCol()),
          where('company', 'array-contains', companySelected)
        )
      : undefined
  );
  const keyUsers = querySnapToMap(keyUsersQS) ?? {};

  const loadingAllUsers = !loadingUsers && !loadingkeyUsers;
  const isLoaded = loadingAllUsers && !!users && !!keyUsers;
  const isError = errorCompanies || errorUsers || errorkeyUsers;

  useEffect(() => {
    const profileUids = [...Object.keys(users || {}), ...Object.keys(keyUsers || {})];

    if (companySelected && profileUids.length > 0) {
      dispatch(
        fetchProfileImages({
          profileUids,
          company: companySelected,
          isThumb: true
        })
      );
    }
  }, [companySelected, users, keyUsers]);

  const handleCompanyChange = (e: SelectChangeEvent<string>) => {
    setCompanySelected(e.target.value);
  };

  const handleOpenParentChange = (parent: string) =>
    setOpenParent((prevState) => {
      if (prevState !== null && parent === prevState) {
        return null;
      }
      return parent;
    });

  const showRemoveUserDialogConfirmation = (uid: string) => {
    const _userData = users?.[uid];
    if (!_userData) {
      return;
    }

    const company = companySelected;

    dispatch(
      updateUi({
        dialog: new MyDialogState({
          title: $t({ id: 'manUsr.remUserTitle' }),
          message: $t(
            { id: 'manUsr.remUserMsg' },
            {
              name: <b key="mub0">{`${_userData.firstName} ${_userData.lastName}`}</b>,
              nl: <br key="munl0" />
            }
          ),
          show: true
        }).setConfirmAction(Actions.REMOVE_USER, { uid, company })
      })
    );
  };

  return (
    <>
      {!isError ? (
        <Container className={classes.container}>
          {!loadingCompanies && !!companies ? (
            <>
              <Grid container className={classes.titleContainer}>
                <Typography variant="h4" sx={text.title}>
                  {$t({ id: 'nav.manageusers' })}
                </Typography>
                <Typography variant="subtitle1" sx={text.subtitle}>
                  {$t({ id: 'admin.manUsersSubtitle' })}
                </Typography>
              </Grid>

              <Grid container className={classes.mainGrid}>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="company-select-label">{$t({ id: 'admin.company' })}</InputLabel>
                    <Select
                      required
                      labelId="company-select-label"
                      id="company-select"
                      name="company"
                      value={companySelected}
                      onChange={handleCompanyChange}
                      input={<OutlinedInput label={$t({ id: 'admin.company' })} />}
                    >
                      <MenuItem value={''}>
                        <Typography variant="body1">{$t({ id: 'none' })}</Typography>
                      </MenuItem>
                      {Object.keys(companies).map((value, index) => (
                        <MenuItem key={index} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container className={classes.mainGrid}>
              <Grid item xs={12}>
                <Skeleton variant="text" height={spacing(10)} animation="wave" />
                <Skeleton variant="text" height={spacing(5)} animation="wave" />
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={spacing(8)}
                  className={classes.margin}
                />
              </Grid>
            </Grid>
          )}

          {isLoaded ? (
            <Grid container className={classes.mainGrid}>
              <Grid item xs={12}>
                <List
                  subheader={
                    <ListSubheader sx={{ backgroundColor: 'background.default' }}>
                      {$t({ id: 'manUsr.users' })}
                    </ListSubheader>
                  }
                >
                  {!isEmpty(users) ? (
                    Object.entries(users).map(([uid, userProfile]) => {
                      const { firstName, lastName, authDomains, pilot, assistant, moderator } =
                        userProfile;
                      const domains = authDomains.join(', ');
                      const profileImageThumb = userData?.profilePicThumbs?.[uid];

                      const userPermissions = [
                        moderator && $t({ id: 'moderator' }),
                        pilot && $t({ id: 'pilot' }),
                        assistant && $t({ id: 'assistant' })
                      ].filter(Boolean);

                      const permissions = userPermissions.join(', ');

                      // Render only moderators with expandable child users
                      if (moderator) {
                        return (
                          <Fragment key={uid}>
                            <ListItemButton
                              alignItems="flex-start"
                              onClick={() => handleOpenParentChange(uid)}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  className={classes.profileImage}
                                  src={profileImageThumb?.src || noImg}
                                  alt={$t({ id: 'altUserProfileImage' })}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                id={uid}
                                className={classes.itemText}
                                primary={`${firstName} ${lastName} (${uid})`}
                                secondary={$t(
                                  { id: 'admin.manUsersListItem1' },
                                  {
                                    permissions: permissions,
                                    nl: <br key={`${uid}nl`} />,
                                    authDomains: domains
                                  }
                                )}
                              />
                              <ListItemSecondaryAction>
                                <Box
                                  sx={{
                                    top: 0,
                                    right: 0,
                                    ml: spacing(6)
                                  }}
                                >
                                  {uid === openParent ? (
                                    <ExpandLess sx={{ color: palette.primary.main }} />
                                  ) : (
                                    <ExpandMore sx={{ color: palette.primary.main }} />
                                  )}
                                </Box>
                                <Tooltip title={$t({ id: 'view' })}>
                                  <IconButton
                                    component={Link}
                                    to={`/profile/${uid}`}
                                    edge="start"
                                    aria-label={$t({ id: 'view' })}
                                    sx={{ color: 'info.main' }}
                                    size="large"
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={$t({ id: 'replace' })}>
                                  <IconButton
                                    component={Link}
                                    to={`/replaceModerator/${uid}`}
                                    edge="end"
                                    aria-label={$t({ id: 'replace' })}
                                    color="warning"
                                  >
                                    <ChangeCircleIcon fontSize="large" />
                                  </IconButton>
                                </Tooltip>
                              </ListItemSecondaryAction>
                            </ListItemButton>

                            {/* Render child users under the parent */}
                            <Collapse in={uid === openParent} timeout="auto" unmountOnExit>
                              {Object.entries(users).map(([childUid, childUserProfile]) => {
                                if (
                                  childUserProfile.parent === uid &&
                                  (childUserProfile.pilot || childUserProfile.assistant)
                                ) {
                                  const _profileImageThumb = userData?.profilePicThumbs?.[childUid];

                                  const { pilot: isPilot, assistant: isAssistant } =
                                    childUserProfile;

                                  const childPermissions = [
                                    isPilot && $t({ id: 'pilot' }),
                                    isAssistant && $t({ id: 'assistant' })
                                  ].filter(Boolean);

                                  return (
                                    <ListItemButton
                                      key={childUid}
                                      alignItems="flex-start"
                                      sx={{ pl: spacing(7) }}
                                    >
                                      <ListItemAvatar>
                                        <Avatar
                                          className={classes.profileImage}
                                          alt={$t({ id: 'altUserProfileImage' })}
                                          src={_profileImageThumb?.src || noImg}
                                        />
                                      </ListItemAvatar>
                                      <ListItemText
                                        id={childUid}
                                        className={classes.itemText}
                                        primary={`${childUserProfile.firstName} ${childUserProfile.lastName} (${childUid})`}
                                        secondary={$t(
                                          { id: 'admin.manUsersListItem1' },
                                          {
                                            permissions: childPermissions.join(', '),
                                            nl: <br key={`${childUid}nl`} />,
                                            authDomains: childUserProfile.authDomains.join(', ')
                                          }
                                        )}
                                      />
                                      <ListItemSecondaryAction>
                                        <Tooltip title={$t({ id: 'view' })}>
                                          <IconButton
                                            component={Link}
                                            to={`/profile/${childUid}`}
                                            edge="start"
                                            aria-label={$t({ id: 'view' })}
                                            sx={{ color: 'info.main', mr: spacing(0.5) }}
                                            size="large"
                                          >
                                            <VisibilityIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title={$t({ id: 'remove' })}>
                                          <IconButton
                                            edge="end"
                                            aria-label={$t({ id: 'remove' })}
                                            sx={{ color: 'error.main' }}
                                            onClick={() =>
                                              showRemoveUserDialogConfirmation(childUid)
                                            }
                                            size="large"
                                          >
                                            <DeleteIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </ListItemSecondaryAction>
                                    </ListItemButton>
                                  );
                                }
                                return null;
                              })}
                            </Collapse>
                          </Fragment>
                        );
                      }
                      return null;
                    })
                  ) : companySelected ? (
                    <ListItem key="emptyUsers" alignItems="flex-start">
                      <ListItemText
                        id="emptyUsers"
                        className={classes.itemText}
                        primary={$t({ id: 'manUsr.noUsers' })}
                      />
                    </ListItem>
                  ) : null}

                  {/* Render key users if available */}
                  {isLoaded
                    ? Object.entries(keyUsers).map(([keyUid, keyUserProfile]) => {
                        const { firstName, lastName, keyUser, company } = keyUserProfile;
                        const profileImageThumb = userData?.profilePicThumbs?.[keyUid];
                        const permissions = keyUser && $t({ id: 'keyUser' });
                        const keyCompanies = company.join(', ');

                        return (
                          <Fragment key={keyUid}>
                            <ListItemButton alignItems="flex-start">
                              <ListItemAvatar>
                                <Avatar
                                  className={classes.profileImage}
                                  src={profileImageThumb?.src || noImg}
                                  alt={$t({ id: 'altUserProfileImage' })}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                id={keyUid}
                                className={classes.itemText}
                                primary={`${firstName} ${lastName} (${keyUid})`}
                                secondary={$t(
                                  { id: 'admin.manUsersListItem2' },
                                  {
                                    permissions: permissions,
                                    nl: <br key={`${keyUid}nl`} />,
                                    companies: keyCompanies
                                  }
                                )}
                              />
                              <ListItemSecondaryAction>
                                <div className={classes.icons}>
                                  <Tooltip title={$t({ id: 'view' })}>
                                    <IconButton
                                      component={Link}
                                      to={`/profile/${keyUid}`}
                                      edge="start"
                                      aria-label={$t({ id: 'view' })}
                                      sx={{ color: 'info.main' }}
                                      size="large"
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title={$t({ id: 'keyUser' })}>
                                    <IconButton
                                      aria-label={$t({ id: 'keyUser' })}
                                      sx={{ ml: 0.5 }}
                                      color="success"
                                      edge="end"
                                      size="large"
                                    >
                                      <VerifiedUserIcon fontSize="medium" />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </ListItemSecondaryAction>
                            </ListItemButton>
                          </Fragment>
                        );
                      })
                    : null}
                </List>

                <Grid item>
                  <ListItem>
                    <Button
                      variant="contained"
                      size="medium"
                      color="secondary"
                      aria-label="addUser"
                      component={Link}
                      to="/addUser"
                      startIcon={<AddCircleIcon />}
                    >
                      {$t({ id: 'manUsr.addUsr' })}
                    </Button>
                  </ListItem>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container className={classes.mainGrid}>
                <Grid item xs={12} sx={{ mt: spacing(2) }}>
                  <Skeleton variant="text" width={spacing(30)} height={spacing(5)} />
                  {Array.from({ length: 7 }).map((_, index) => (
                    <Box key={`skeleton-${index}`}>
                      <Skeleton
                        variant="rounded"
                        height={spacing(8)}
                        animation="wave"
                        className={classes.margin}
                      />
                    </Box>
                  ))}
                  <Skeleton variant="rounded" width={spacing(20)} height={spacing(5)} />
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      ) : (
        <Typography variant="body1">An error occurred: {isError.message}</Typography>
      )}
    </>
  );
};

export default ManageUsers;
