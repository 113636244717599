import axios from 'axios';
import { FirebaseError } from 'firebase/app';
import { signInWithCustomToken, signOut, Unsubscribe } from 'firebase/auth';
import { getDoc, onSnapshot } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { CustomClaims } from 'flyid-core/dist/Database/Models/Settings/CustomClaims';
import {
  getAuthenticationProviderSettingsDoc,
  getStorageKeyUserProfileImagePath,
  getStorageKeyUserProfileThumbPath,
  getStorageUserProfileImagePath,
  getStorageUserProfileThumbPath,
  getUserProfileDoc
} from 'flyid-core/dist/Util/database';
import { getSnackbar } from 'src/util/helpers/server';
import { decodeText } from 'src/util/web';
import { urlDataReader } from '../../workers/fileWorkerApi';

import { buildDocumentRef } from 'src/firebase/firestore';
import { silentyNavigateTo } from 'src/router';
import authAxios from 'src/util/axios';
import { setAuthProvider } from '../reducers/firestoreReducer';
import { updateUi } from '../reducers/uiReducer';
import { setProfileImageData, setProfileImageThumb, setUserProfile } from '../reducers/userReducer';
import { AdminCallPayload } from '../types/common';
import { ThunkActionType } from './../store';

let authSettingsListenerUnsubscriber: Unsubscribe | null = null;
export const listenToAuthSettings = (): ThunkActionType => (dispatch) => {
  authSettingsListenerUnsubscriber?.(); // Unsubscribe before subscribing again
  authSettingsListenerUnsubscriber = onSnapshot(
    buildDocumentRef(getAuthenticationProviderSettingsDoc()),
    {
      next: (authSettingsDS) => {
        const authSettings = authSettingsDS.data();
        if (authSettings) {
          dispatch(setAuthProvider(authSettings));
        }
      },
      error: (error) => console.error(`Failed fetching auth providers: (${error.message})`)
    }
  );
};

export type LoginParams = { email: string; password: string };
export const loginAction =
  (data: LoginParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isLoginLoading: true } }));

    // Fetch custom token from server with user credentials
    axios({
      url: `/admin/signin`,
      method: 'post',
      data: data
    })
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const customToken = res.data.token as string | undefined;
        if (customToken) {
          return signInWithCustomToken(getAuth(), customToken);
        }
        throw Error(res.statusText);
      })
      .then((userCredential) => {
        const user = userCredential.user;
        if (!user) throw new Error('Something went wrong during login!');

        dispatch(fetchUserData({ uid: user.uid, emailVerified: user.emailVerified }));
        dispatch(updateUi());
        silentyNavigateTo('/');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isLoginLoading: false
            }
          })
        );
      });
  };

export type FetchUserDataParams = {
  uid: string;
  emailVerified: boolean;
};
export const fetchUserData =
  (data: FetchUserDataParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    const profileRef = buildDocumentRef(getUserProfileDoc(data.uid));

    return Promise.all([getDoc(profileRef), getAuth().currentUser?.getIdTokenResult(true)])
      .then(([userProfileSnap, token]) => {
        if (!userProfileSnap.exists()) throw new Error('Missing user profile!');
        if (!token) throw new Error('Missing user profile!');

        dispatch(
          setUserProfile({
            uid: data.uid,
            emailVerified: data.emailVerified,
            profile: userProfileSnap.data(),
            claims: token.claims as unknown as CustomClaims
          })
        );
      })
      .catch(() => dispatch(updateUi()));
  };

export type LogoutParams = undefined;
export const logoutAction =
  (): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    signOut(getAuth()).catch(() => dispatch(updateUi()));
  };

export type FetchProfileImagesParams = {
  profileUids: string[];
  company: string | string[];
  isThumb?: boolean;
};
export const fetchProfileImages =
  (data: FetchProfileImagesParams): ThunkActionType =>
  (dispatch, getState, { getProfilePicsBucket }) => {
    const { profileUids, company, isThumb } = { isThumb: true, ...data };

    const profilePicsState = getState().user[isThumb ? 'profilePicThumbs' : 'profilePics'];

    profileUids.forEach((uid) => {
      const thisProfilePic = profilePicsState[uid];
      // Only fetch profile if not loading nor loaded
      if (!thisProfilePic || (!thisProfilePic.isLoading && !thisProfilePic.isLoaded)) {
        const action = isThumb ? setProfileImageThumb : setProfileImageData;
        dispatch(action({ uid, picData: { isLoaded: false, isLoading: true } }));

        const imgPath = Array.isArray(company)
          ? isThumb
            ? getStorageKeyUserProfileThumbPath(uid)
            : getStorageKeyUserProfileImagePath(uid)
          : isThumb
            ? getStorageUserProfileThumbPath(company, uid)
            : getStorageUserProfileImagePath(company, uid);

        getDownloadURL(ref(getProfilePicsBucket(), imgPath))
          .then((url) =>
            axios({
              baseURL: undefined,
              url,
              method: 'GET',
              responseType: 'blob'
            })
          )
          .then((res) =>
            urlDataReader(res.data as Blob, {
              onload: (result) => {
                const src = result
                  ? typeof result === 'string'
                    ? result
                    : decodeText(result)
                  : undefined;
                dispatch(
                  action({
                    uid,
                    picData: { src, isLoaded: true, isLoading: false }
                  })
                );
              },
              onerror: (err) => console.log(err)
            })
          )
          .catch((err) => {
            if (err instanceof FirebaseError) {
              if (String(err.code) !== '404' && err.code !== 'storage/object-not-found')
                console.error(err);
            } else console.error(err);
            dispatch(
              action({
                uid,
                picData: { src: undefined, isLoaded: true, isLoading: false }
              })
            );
          });
      }
    });
  };

export type AddUserParams = {
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  authDomains?: string[];
  sendEmail?: boolean;
  password: string;
  assistant?: boolean;
  moderator?: boolean;
  pilot?: boolean;
  keyUser?: boolean;
  admin?: boolean;
  parent?: string;
  companies?: string[];
  usesAuthProvider: boolean;
};

export const addUser =
  (data: AddUserParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isUserActionLoading: true } }));

    const _data: AdminCallPayload = {
      posArgs: [data.company, data.email, data.firstName, data.lastName, data.employeeId],
      listArgs: {
        authDomains: data.authDomains,
        sendEmail: [data.sendEmail ?? false],
        password: [data.password],
        assistant: [data.assistant ?? false],
        pilot: [data.pilot ?? false],
        moderator: [data.moderator ?? false],
        keyUser: [data.keyUser ?? false],
        admin: [data.admin ?? false],
        parent: data.parent ? [data.parent] : undefined,
        companies: data.companies,
        usesAuthProvider: [data.usesAuthProvider]
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'createUser'
      },
      headers: {
        company: data.company
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
        silentyNavigateTo('/manageUsers');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
      });
  };

export type EditUserParams = {
  uid: string;
  company: string;
  email: string;
  firstName: string;
  lastName: string;
  employeeId: string;
  profilePicFile?: string;
  authDomains?: string[];
  authLicenses?: string[];
  assistant?: boolean;
  pilot?: boolean;
  moderator?: boolean;
  keyUser?: boolean;
  admin?: boolean;
  companies?: string[];
};

export const editUser =
  (data: EditUserParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isUserActionLoading: true } }));

    const _data: AdminCallPayload = {
      posArgs: [data.uid],
      listArgs: {
        company: [data.company],
        email: [data.email],
        firstName: [data.firstName],
        lastName: [data.lastName],
        employeeId: [data.employeeId],
        profilePicFile: data.profilePicFile ? [data.profilePicFile] : undefined,
        authDomains: data.authDomains,
        authLicenses: data.authLicenses,
        assistant: [data.assistant ?? false],
        pilot: [data.pilot ?? false],
        moderator: [data.moderator ?? false],
        keyUser: [data.keyUser ?? false],
        admin: [data.admin ?? false],
        companies: data.companies
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'editUser'
      },
      headers: {
        company: data.company
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
        if (data.profilePicFile) {
          const resetImageData = { uid: data.uid, picData: { isLoaded: false, isLoading: false } };
          dispatch(setProfileImageData({ ...resetImageData }));
          dispatch(setProfileImageThumb({ ...resetImageData }));
        }
        silentyNavigateTo('/manageUsers');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
      });
  };

export type RemoveUserParams = {
  company: string;
  uid: string;
};

export const removeUser =
  (data: RemoveUserParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(
      updateUi({
        backdrop: {
          message: { msgCode: 'manUsr.removingUsr', msg: 'Removing user...' },
          show: true
        }
      })
    );

    const _data: AdminCallPayload = {
      posArgs: [data.company, data.uid],
      listArgs: {}
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'removeUser'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
        silentyNavigateTo('/manageUsers');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
      });
  };

export type ResetUserPasswordParams = { uid: string; isPin: boolean };
export const resetUserPassword =
  (data: ResetUserPasswordParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    const _data: AdminCallPayload = {
      posArgs: [data.uid],
      listArgs: { new: ['000000'], isPin: [data.isPin] }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'resetPw'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
        silentyNavigateTo('/manageUsers');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
      });
  };

export type ChangePasswordParams = { currentPassword?: string; newPassword: string };
export const changePassword =
  (data: ChangePasswordParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isUserActionLoading: true } }));

    authAxios(getAuth(), getState(), {
      url: `/users/pw`,
      method: 'patch',
      data
    })
      .then(() => dispatch(logoutAction()))
      .catch(() => dispatch(updateUi()));
  };

export type ReplaceModeratorParams = {
  fromUid: string;
  toUid: string;
  skipCheck?: boolean;
};
export const replaceModerator =
  (data: ReplaceModeratorParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isUserActionLoading: true } }));

    const _data: AdminCallPayload = {
      posArgs: [data.fromUid, data.toUid],
      listArgs: { skipCheck: [true] }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'replaceModerator'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
        silentyNavigateTo('/manageUsers');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isUserActionLoading: false
            }
          })
        );
      });
  };
