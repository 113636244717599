import { AuthenticationMethod } from 'flyid-core/dist/Database/Models/Settings/APIAuthentication';
import { silentyNavigateTo } from 'src/router';
import authAxios from 'src/util/axios';
import { getSnackbar } from 'src/util/helpers/server';
import { updateUi } from '../reducers/uiReducer';
import { ThunkActionType } from '../store';
import { AdminCallPayload } from '../types/common';

export type SetupCompanyParams = {
  company: string;
  exhibitionName: string;
  timezone?: string;
  moderator?: {
    email: string;
    firstName: string;
    lastName: string;
    employeeId?: string;
  };
  sendEmail?: boolean;
  featureLocks?: string[];
};
export const setupCompany =
  (data: SetupCompanyParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isCompanyActionLoading: true } }));

    const { user } = getState();
    const _data: AdminCallPayload = {
      posArgs: [data.company, data.exhibitionName],
      listArgs: {
        timezone: data.timezone ? [data.timezone] : undefined,
        moderator: data.moderator ? Object.values(data.moderator) : undefined,
        sendEmail: [data.sendEmail ?? false],
        featureLocks: data.featureLocks,
        // Grants company access to this user if is key user as well
        grantAccessToKeyUser: user.claims.keyUser ? [getAuth().currentUser!.uid] : undefined
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: _data,
      params: {
        command: 'setupCompany'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isCompanyActionLoading: false
            }
          })
        );
        silentyNavigateTo('/');
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isCompanyActionLoading: false
            }
          })
        );
      });
  };

export type SetupInterfaceSettingsParams = {
  company: string;
  clientType: 'webservice' | 'sap';
  flattenOutput: boolean;
  noBaseFields: boolean;
  useInputOrder: boolean;
  useCustomTransformer: boolean;
  deleteOnPush: boolean;
  pushUrl?: string;
  isTriggerActive: boolean;
  activeDomains?: string[];
  headers?: Record<string, string>;
  authentication?: AuthenticationMethod[];
  // SAP-specific params
  connParams?: {
    user: string;
    passwd: string;
    ashost: string;
    sysnr: string;
    client: string;
    lang: string;
  };
  targetRFC?: string;
  options?: {
    retry?: string;
  };
};

export const setupInterfaceSettings =
  (params: SetupInterfaceSettingsParams): ThunkActionType =>
  (dispatch, getState, { getAuth }) => {
    dispatch(updateUi({ loadingButton: { isCompanyActionLoading: true } }));

    const data: AdminCallPayload = {
      posArgs: [params.company],
      listArgs: {
        clientType: [params.clientType],
        flattenOutput: [params.flattenOutput ?? false],
        noBaseFields: [params.noBaseFields ?? false],
        useInputOrder: [params.useInputOrder ?? false],
        useCustomTransformer: [params.useCustomTransformer ?? false],
        pushUrl: params.pushUrl ? [params.pushUrl] : undefined,
        deleteOnPush: [params.deleteOnPush ?? false],
        isTriggerActive: [params.isTriggerActive ?? false],
        activeDomains: params.activeDomains,
        headers: params.headers ? [JSON.stringify(params.headers)] : undefined,
        authentication: params.authentication ? [JSON.stringify(params.authentication)] : undefined,
        ...(params.clientType === 'sap' && {
          connParams: params.connParams ? Object.values(params.connParams) : undefined,
          targetRFC: params.targetRFC ? [params.targetRFC] : undefined,
          options: params.options ? Object.values(params.options) : undefined
        })
      }
    };

    authAxios(getAuth(), getState(), {
      url: `/admin/execute`,
      method: 'post',
      data: data,
      params: {
        command: 'setupInterfaceSettings'
      }
    })
      .then((res) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(res),
            loadingButton: {
              isCompanyActionLoading: false
            }
          })
        );
      })
      .catch((err: Error) => {
        dispatch(
          updateUi({
            snackbar: getSnackbar(err),
            loadingButton: {
              isCompanyActionLoading: false
            }
          })
        );
      });
  };
