import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useIntl } from 'react-intl';
import { Navigate, Outlet } from 'react-router-dom';
import { auth } from 'src/firebase/firebase';
import { useAppDispatch, useAppSelector } from 'src/hooks/reduxHooks';
import { logoutAction } from 'src/redux/actions/userActions';
import { updateUi } from 'src/redux/reducers/uiReducer';
import { useAppTheme } from 'src/theme/theme';
import BadRequest from '../widgets/BadRequest';
import LoadingCircle from '../widgets/LoadingCircle';

const ProtectedRoute: React.FC = () => {
  const dispatch = useAppDispatch();
  const [authUser, authLoading] = useAuthState(auth);
  const { userData } = useAppSelector(({ user }) => ({ userData: user }));
  const { profile, emailVerified, isLoaded: isProfileLoaded } = userData;

  // Make sure any logged-in user cookies gets logged out immediately if not an admin
  useEffect(() => {
    if (userData.claims && isProfileLoaded) {
      if (!userData.claims.admin || !userData.profile.admin) {
        dispatch(updateUi({ snackbar: { message: 'Not an admin!', severity: 'error' } }));
        dispatch(logoutAction());
      }
    }
  }, [userData]);

  const signedIn = Boolean(!authLoading && authUser);
  const isLoginPreparing = authLoading || (signedIn && !isProfileLoaded);

  const { $t } = useIntl();
  const theme = useAppTheme();

  // Loading authentication or profile data
  if (isLoginPreparing) return <LoadingCircle />;
  else if (signedIn) {
    if (!emailVerified) {
      return (
        <BadRequest
          text={$t({ id: 'lacksEmailVerification' })}
          iconStyle={{ color: theme.palette.warning.main }}
        />
      );
    }

    // Inform of network blockage when lacking profile data
    if (profile?.hasOwnProperty('pwUpdated') === false) {
      return (
        <BadRequest
          text={$t({ id: 'networkIsBlockingDomains' })}
          iconStyle={{ color: theme.palette.warning.main }}
        />
      );
    }

    return <Outlet />;
  }
  // Not logged in
  return <Navigate to="/login" />;
};

export default ProtectedRoute;
