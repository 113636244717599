import { configureStore } from '@reduxjs/toolkit';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { FirebaseApp } from 'firebase/app';
import { Auth } from 'firebase/auth';
import { Firestore } from 'firebase/firestore';
import type { FirebaseStorage } from 'firebase/storage';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import rootReducer from './reducers/rootReducer';

// Add to redux thunks to avoid cyclic dependencies by direct access usage
type FirebaseMiddleware = {
  getFirebase: () => FirebaseApp;
  getAuth: () => Auth;
  getFirestore: () => Firestore;
  getDefautBucket: () => FirebaseStorage;
  getProfilePicsBucket: () => FirebaseStorage;
};

let store: ToolkitStore;
export default function initStore(
  storeInput: {
    firebaseApp: FirebaseApp;
    auth: Auth;
    firestore: Firestore;
    defaultBucket: FirebaseStorage;
    profilePicsBucket: FirebaseStorage;
  },
  preloadedState?: Partial<ReturnType<typeof rootReducer>>
) {
  const extraArgument: FirebaseMiddleware = {
    getFirebase: () => storeInput.firebaseApp,
    getAuth: () => storeInput.auth,
    getFirestore: () => storeInput.firestore,
    getDefautBucket: () => storeInput.defaultBucket,
    getProfilePicsBucket: () => storeInput.profilePicsBucket
  };

  // Initialization also sets up the global store
  const thisStore = configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: { extraArgument },
        serializableCheck: false
      }),
    devTools: !process.env.REACT_APP_ENV?.includes('production')
  });
  // Keep type
  store = thisStore;
  return thisStore;
}

export type AppStore = ReturnType<typeof initStore>;
export type ThunkMiddleware = FirebaseMiddleware;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];

export type ThunkActionType<R = void> = ThunkAction<R, AppState, ThunkMiddleware, AnyAction>;

// Export initialized global from here. It should be initialized first.
export const getStore = () => store as AppStore;
